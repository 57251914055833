import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent } from './home/home.component';
import { AuthComponent } from './layout/auth/auth.component';
import { MainAppComponent } from './layout/main-app/main-app.component';
import { RegoffComponent } from './pages/regoff/regoff.component';
import { WelcomeScreenComponent } from './pages/welcome-screen/welcome-screen.component';
import { RegistrationComponent } from './registration/registration.component';
import { DashboardTkComponent } from './pages/dashboard-tk/dashboard-tk.component';

const routes: Routes = [
  { 
    path: '', 
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'register',
        component: RegistrationComponent
      },
      {
        path: 'wellcome',
        component: WelcomeScreenComponent
      },
      {
        path: 'login',
        loadChildren: () => import('./auth/auth-routing.module').then(m => m.AuthRoutingModule)
      }
    ] 
  },
  { 
    path: '', 
    component: MainAppComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./main-app/main-app-routing.module').then(m => m.MainAppRoutingModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'dashboard-tk',
        component: DashboardTkComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'regoff',
        component: RegoffComponent,
        canActivate:[AuthGuard]
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
