import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'
import { NetworkService } from '../helper/network.service';

let CORE_GENERALAPI: String;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http: HttpClient, private networkService: NetworkService) {
    CORE_GENERALAPI = environment.CORE_GENERAL_API_URL;
  }

  getAllInstansi(): Observable<any> {
    return this.http.get(CORE_GENERALAPI + '/v1/getInstansi');
  }

  getCurrentInstansi() {
    const code = this.networkService.currentDomain();
    return this.http.post(CORE_GENERALAPI + '/v1/getInstansiByKode', {
      "kode":code.toLowerCase()
    }, httpOptions);
  }

  addRegistrant(payload: any): Observable<any> {
    return this.http.post(`${CORE_GENERALAPI}/v1/registrant/signup`, payload, httpOptions);
  }

  registrantLogin(payload: any): Observable<any> {
    return this.http.post(`${CORE_GENERALAPI}/v1/registrant/signin`, payload, httpOptions);
  }

  getAllProvinsi(): Observable<any> {
    return this.http.get(CORE_GENERALAPI + '/v1/getProvinsi');
  }

  getKabupatenByProvinsi(params: any): Observable<any> {
    return this.http.get(CORE_GENERALAPI + '/v1/kotabyprovinsi', { 'params': params });
  }

  getKecamatanByKabupatenProvinsi(params: any): Observable<any> {
    return this.http.get(CORE_GENERALAPI + '/v1/kecamatanbykota', { 'params': params });
  }

  getKelurahanByKecamatanKabupatenProvinsi(params: any): Observable<any> {
    return this.http.get(CORE_GENERALAPI + '/v1/kelurahanbykecamatan', { 'params': params });
  }
}
