import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkService } from '../helper/network.service';
import { UiService } from '../helper/ui.service';
import { GeneralService } from '../service/general.service';
import { CoreService } from '../service/core.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Validation from '../helper/validation';
import { CommonService } from '../helper/common.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  allInstansi: any = [
    {
      "id":0,
      "nama_instansi":"",
      "kode_instansi":""
    }
  ];

  listsLembaga: any = [];
  listsTingkat: any = [];
  gelombangPenndaftaran: any;

  choosenInstansi: String = "";
  errorMessage = '';
  isRegisterOpen = false;

  formDaftarSiswa: FormGroup = new FormGroup({
    id_periode: new FormControl(''),
    instansi: new FormControl(''),
    nama_lengkap: new FormControl(''),
    gender: new FormControl(''),
    lembaga_tujuan: new FormControl(''),
    tingkat_id: new FormControl(''),
    telepon: new FormControl(''),
    email: new FormControl(''),
    username: new FormControl(''),
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
    angkatan: new FormControl(''),
  });
  submitted = false;
  showPassword: boolean = false;


  constructor(
    private generalService: GeneralService,
    private coreService: CoreService, 
    private uiService: UiService, 
    private networkService: NetworkService, 
    private router: Router,
    private commonService: CommonService,
    private formBuilder: FormBuilder) 
  { 
    
  }

  ngOnInit(): void {    
    this.getAllInstansi();
    this.initForm();
  }

  initForm() {
    this.formDaftarSiswa = this.formBuilder.group(
      {
        id_periode: ['', Validators.required],
        instansi: ['', Validators.required],
        nama_lengkap: ['', Validators.required],
        gender: ['', Validators.required],        
        lembaga_tujuan: ['', Validators.required],
        tingkat_id: ['', Validators.required],
        telepon: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(13),
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        username: ['', Validators.required],
        password: [
          '', 
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40),
          ],
        ],
        confirmPassword: ['', Validators.required],
        angkatan: ['', Validators.required],
      },
      {
        validators: [Validation.match('password', 'confirmPassword')]
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formDaftarSiswa.controls;
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  gotoHomePage() {
    this.router.navigate(['home']);
  }

  getAllInstansi() {
    this.uiService.showLoader();

    this.generalService.getAllInstansi().subscribe({
      next: data => {
        console.log(data);
        this.allInstansi = data;        
        this.uiService.hideLoader();
      },
      error: err => {
        const errorMessage = err.error.message;
        
        this.uiService.hideLoader();
        
        this.uiService.showError(errorMessage);
      }
    });
  }

  getGelombangPendaftaran() {
    this.uiService.showLoader();

    this.coreService.getOpenRegistration().subscribe({
      next: data => {
        console.log("AMBIL DATA REG", data);
        if (data) {
          // ceck periode pendaftaran
          const startDate = data.start_date;
          const endDate = data.end_date;

          this.isRegisterOpen = this.commonService.getCurrentDateBetweenDateRange(startDate, endDate);
          console.log("OPENS?", this.isRegisterOpen);
          
          this.gelombangPenndaftaran = data;
          this.formDaftarSiswa.controls['id_periode'].setValue(data.id_tahun_ajaran);
          this.formDaftarSiswa.controls['angkatan'].setValue(data.Periode.name);
        }
        this.uiService.hideLoader();
      },
      error: err => {
        this.gelombangPenndaftaran = null;
        console.log('ERROR', err);
        const errorMessage = err.message;
        
        this.uiService.hideLoader();
        
        this.uiService.showError(errorMessage);
      }
    });
  }

  getLembaga() {
    this.uiService.showLoader();

    this.coreService.getAllLembaga('').subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsLembaga = resdata;
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }


  changeLembaga(e: any) {
    console.log(e.target.value);
    const lembagaId = e.target.value;
    this.getTingkatLembaga(lembagaId);
  }
  
  private getTingkatLembaga(lembagaId: any, curentTingkat: any = null) {
    this.uiService.showLoader();
  
    this.coreService.getTingkatByLembagaId(lembagaId).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsTingkat = resdata;
        if (curentTingkat !== null) {
          this.formDaftarSiswa.controls['tingkat_id'].setValue(curentTingkat);
        } else {
          this.formDaftarSiswa.controls['tingkat_id'].setValue('');
        }
      
        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeInstansi(e: any) {
    this.isRegisterOpen = false;
    this.choosenInstansi = e.target.value;
    this.networkService.setCoreApiUrl(this.choosenInstansi);
    this.getGelombangPendaftaran();
    this.getLembaga();
  }

  onSubmit(): void {
    this.submitted = true;

    if (!this.isRegisterOpen) {
      this.uiService.showError('Mohon maaf pedaftaran santri baru belum dibuka');
      return;
    }
    
    if (this.formDaftarSiswa.invalid) {
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    console.log(JSON.stringify(this.formDaftarSiswa.value, null, 2));

    this.createRegistrant();
  }
  
  private createRegistrant() {
    this.uiService.showLoader();
    this.generalService.addRegistrant(this.formDaftarSiswa.value).subscribe({
      next: resdata => {
        console.log(resdata);
        
        this.uiService.hideLoader();
        this.uiService.showSuccess('Pendaftaran sukses, silahkan login untuk melanjutkan');
      
        this.router.navigate(['/login']);

      },
      error: err => {
        console.log(err);
        
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);
        
        this.uiService.hideLoader();
        
        this.uiService.showError(this.errorMessage);
      }
    });
  }

}
