import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mail-icon',
  templateUrl: './MailIcon.component.html',
})

export class MailIconComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
