<div class="d-flex justify-content-center">
  <div class="d-flex py-5 flex-column">
    <div class="position-relative w-100 flex align-items-center">
      <img (click)="gotoHomePage()"
        src="../../assets/images/back-button.png"
        class="position-absolute responsive-button-image" alt=""
        style="width: 60px; cursor: pointer; left: 0%; top: 50%; transform: translateY(-50%);">
      <div class="fw-bold fs-4 text-center">
        Registrasi Pendaftaran Santri Baru
      </div>
    </div>
    <div class="text-center mt-4" *ngIf="choosenInstansi">
      <div *ngIf="!isRegisterOpen">
        <span class="text-danger">Mohon maaf pedaftaran santri baru belum dibuka.</span>
      </div>
      <div *ngIf="gelombangPenndaftaran">
        {{ gelombangPenndaftaran.title }}
      </div>
    </div>
      <form [formGroup]="formDaftarSiswa" (ngSubmit)="onSubmit()">
        <div class="shadow mt-4 p-4 px-5 d-flex flex-column gap-3">
          <div class="d-flex gap-2 flex-column">
            <div><span><home-icon></home-icon></span> Nama Sekolah<span class="text-danger">*</span></div>
            <div class="w-100 position-relative">
              <select
                  formControlName="instansi"
                  [ngClass]="{ 'is-invalid': submitted && f['instansi'].errors }"
                  class="py-2 rounded-3 w-100" style="text-indent: 10px; border: 1px solid rgb(170, 170, 170); outline: none;"
                  (change)="changeInstansi($event)">
                  <option value="" selected disabled>-- Pilih Lembaga Sekolah --</option>
                  <option *ngFor="let instansi of allInstansi" [value]="instansi.kode_instansi">{{instansi.nama_instansi}}</option>
              </select>
              <img class="position-absolute responsive-image"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACBSURBVHgBdY7NEYUgDIQTacDugJOU8F4Fagd2IJ74GVoEjI7OxBH3tJP9NgnGGA0ArLVWI6XcoKGU0lBKWcj+OzL9MUREG0IYPmBLts85Q6eUWmj73CoxGA5Ga23xDgmcqDBeoRFCAIfp3elcys/z0i0OvwqNSw/4U845473/tbIdX+tUaJysUf8AAAAASUVORK5CYII="
                alt="" style="right: 5%; top: 50%; transform: translateY(-50%);">
            </div>
            <div *ngIf="submitted && f['instansi'].errors" style="color: rgb(255, 126, 126);">*Nama sekolah tidak boleh kosong</div>
          </div>
          <div class="d-flex gap-2 flex-column">
            <div><span><user-icon></user-icon></span> Nama Calon Santri Sesuai Ijazah<span class="text-danger">*</span></div>
            <div class="w-100 position-relative">
              <input
                type="text"
                formControlName="nama_lengkap"
                [ngClass]="{ 'is-invalid': submitted && f['nama_lengkap'].errors }"
                class="py-2 rounded-3 w-100" placeholder="Nama"
                style="text-indent: 10px; border: 1px solid rgb(170, 170, 170); outline: none;"
              />
              <div *ngIf="submitted && f['nama_lengkap'].errors" style="color: rgb(255, 126, 126);">*Nama tidak boleh kosong</div>
            </div>
          </div>
          <div class="d-flex gap-2 flex-column">
            <div><span><gender-icon></gender-icon></span> Jenis Kelamin<span class="text-danger">*</span></div>
            <div class="w-100 position-relative">
              <select
                  formControlName="gender"
                  [ngClass]="{ 'is-invalid': submitted && f['gender'].errors }"
                  class="py-2 rounded-3 w-100" style="text-indent: 10px; border: 1px solid rgb(170, 170, 170); outline: none;">
                  <option value="" selected disabled>Pilih</option>
                  <option value="L">Laki-laki</option>
                  <option value="P">Perempuan</option>
              </select>
              <img class="position-absolute responsive-image"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACBSURBVHgBdY7NEYUgDIQTacDugJOU8F4Fagd2IJ74GVoEjI7OxBH3tJP9NgnGGA0ArLVWI6XcoKGU0lBKWcj+OzL9MUREG0IYPmBLts85Q6eUWmj73CoxGA5Ga23xDgmcqDBeoRFCAIfp3elcys/z0i0OvwqNSw/4U845473/tbIdX+tUaJysUf8AAAAASUVORK5CYII="
                alt="" style="right: 5%; top: 50%; transform: translateY(-50%);">
            </div>
            <div *ngIf="submitted && f['gender'].errors" style="color: rgb(255, 126, 126);">*Jenis kelamin tidak boleh kosong</div>
          </div>
          <div class="d-flex gap-2 flex-column">
            <div><span><home-icon></home-icon></span> Lembaga<span class="text-danger">*</span></div>
            <div class="w-100 position-relative">
              <select
                  formControlName="lembaga_tujuan"
                  [ngClass]="{ 'is-invalid': submitted && f['lembaga_tujuan'].errors }"
                  class="py-2 rounded-3 w-100" style="text-indent: 10px; border: 1px solid rgb(170, 170, 170); outline: none;"
                  (change)="changeLembaga($event)">
                  <option value="" selected disabled>Pilih</option>
                  <option [value]="data.id" *ngFor="let data of listsLembaga">{{data.nama_lembaga}}</option>
              </select>
              <img class="position-absolute responsive-image"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACBSURBVHgBdY7NEYUgDIQTacDugJOU8F4Fagd2IJ74GVoEjI7OxBH3tJP9NgnGGA0ArLVWI6XcoKGU0lBKWcj+OzL9MUREG0IYPmBLts85Q6eUWmj73CoxGA5Ga23xDgmcqDBeoRFCAIfp3elcys/z0i0OvwqNSw/4U845473/tbIdX+tUaJysUf8AAAAASUVORK5CYII="
                alt="" style="right: 5%; top: 50%; transform: translateY(-50%);">
            </div>
          </div>
          <div class="d-flex gap-2 flex-column">
            <div><span><bookmark-icon></bookmark-icon></span> Tingkatan<span class="text-danger">*</span></div>
            <div class="w-100 position-relative">
              <select
                  formControlName="tingkat_id"
                  [ngClass]="{ 'is-invalid': submitted && f['tingkat_id'].errors }"
                  class="py-2 rounded-3 w-100" style="text-indent: 10px; border: 1px solid rgb(170, 170, 170); outline: none;">
                  <option value="" selected disabled>Pilih</option>
                  <option [value]="data.id" *ngFor="let data of listsTingkat">{{data.nama_tingkat}}</option>
              </select>
              <img class="position-absolute responsive-image"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACBSURBVHgBdY7NEYUgDIQTacDugJOU8F4Fagd2IJ74GVoEjI7OxBH3tJP9NgnGGA0ArLVWI6XcoKGU0lBKWcj+OzL9MUREG0IYPmBLts85Q6eUWmj73CoxGA5Ga23xDgmcqDBeoRFCAIfp3elcys/z0i0OvwqNSw/4U845473/tbIdX+tUaJysUf8AAAAASUVORK5CYII="
                alt="" style="right: 5%; top: 50%; transform: translateY(-50%);">
            </div>
            <div *ngIf="submitted && f['tingkat_id'].errors" style="color: rgb(255, 126, 126);">*Tingkatan tidak boleh kosong</div>
          </div>
          <div class="d-flex gap-2 flex-column">
            <div><span><mail-icon></mail-icon></span> Email<span class="text-danger">*</span></div>
            <div class="w-100 position-relative">
              <input
                type="email"
                formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                class="py-2 rounded-3 w-100" placeholder="@gmail.com"
                style="text-indent: 10px; border: 1px solid rgb(170, 170, 170); outline: none;"
              />
            </div>
            <div *ngIf="submitted && f['email'].errors" style="color: rgb(255, 126, 126);">*Email tidak boleh kosong</div>
          </div>
          <div class="d-flex gap-2 flex-column">
            <div><span><phone-icon></phone-icon></span> Telepon Aktif (Support WhatsApp)<span class="text-danger">*</span></div>
            <div class="w-100 position-relative">
              <input
                type="text"
                formControlName="telepon"
                [ngClass]="{ 'is-invalid': submitted && f['telepon'].errors }"
                class="py-2 rounded-3 w-100" placeholder="081207xxx"
                style="text-indent: 10px; border: 1px solid rgb(170, 170, 170); outline: none;"
              />
            </div>
            <div *ngIf="submitted && f['telepon'].errors" style="color: rgb(255, 126, 126);">*Nomor Telpon harus berupa angka antara 8 dan 13 digit</div>
          </div>
          <div class="d-flex gap-2 flex-column">
            <div><span><user-plus-icon></user-plus-icon></span> Username<span class="text-danger">*</span></div>
            <div class="w-100 position-relative">
              <input
                formControlName="username"
                class="py-2 rounded-3 w-100" placeholder="Username"
                [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"
                style="text-indent: 10px; border: 1px solid rgb(170, 170, 170); outline: none;">
                <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXCAYAAAARIY8tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE2SURBVHgB7ZTLbYQwEECH3wU4UALpIOlg00G48BGHDRVstoLVdkAHkBMS4pAOsh2EEijBFy4gIGOJQ9iAjYxyyGqfhGzwaJ6FPQPw35HWBGVZtlNV9TQMwyO+WpIkXZqmOYZhWG4WFEWx7/s+nVtD4avnee8gKsCd24qifOLUXgghdV0/RFFElnLIwACT7xjJKZZhGC+MdbYAf4ENHHgxTAEeZgUc8HwqYYGmaR84EEZIhc8FRAWO45Cu646MkHMQBBWICiiYIMXhid798ROhcxQ/u66bws2zWGhJklimaR5otcJyLZSyLJdt2y6exaxgbA8xTi1YB8H4s+/7MVeQ5/kBhxgEmOtNE8HYe75g/c6v+dWbJtcUC+u0ITnF0nX97eeHiWDs95vAGtn/qQCubhy3ku98A6GLckdedy4FAAAAAElFTkSuQmCC"
                alt="user" class="position-absolute responsive-image"
                style="right: 5%; top: 50%; transform: translate(5%, -50%);">
            </div>
            <div *ngIf="submitted && f['username'].errors" style="color: rgb(255, 126, 126);">*Username tidak boleh kosong</div>
          </div>
          <div class="d-flex gap-2 flex-column">
            <div><span><lock-icon></lock-icon></span> Password<span class="text-danger">*</span></div>
            <div class="w-100 position-relative">
              <input
                [type]=" showPassword ? 'text' : 'password' "
                formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                class="py-2 rounded-3 w-100" placeholder="Password"
                style="text-indent: 10px; border: 1px solid rgb(170, 170, 170); outline: none;">
                <img
                *ngIf="showPassword"
                (click)='showHidePassword()'
                src="../../assets/images/open-eye.png"
                alt="user" class="responsive-image position-absolute"
                style="cursor: pointer; right: 5%; top: 50%; transform: translate(5%, -50%);">
                <img
                *ngIf="!showPassword"
                (click)='showHidePassword()'
                src="../../assets/images/close-eye.png"
                alt="user" class="responsive-image position-absolute"
                style="cursor: pointer; right: 5%; top: 50%; transform: translate(5%, -50%);">
            </div>
            <div *ngIf="submitted && f['password'].errors" style="color: rgb(255, 126, 126);">*Password tidak boleh kosong, minimal 6 karakter</div>
          </div>
          <div class="d-flex gap-2 flex-column">
            <div><span><lock-icon></lock-icon></span> Konfirmasi Password<span class="text-danger">*</span></div>
            <div class="w-100 position-relative">
              <input
                [type]=" showPassword ? 'text' : 'password' "
                formControlName="confirmPassword"
                [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }"
                class="py-2 rounded-3 w-100" placeholder="Konfirmasi Password"
                style="text-indent: 10px; border: 1px solid rgb(170, 170, 170); outline: none;">
                <img
                *ngIf="showPassword"
                (click)='showHidePassword()'
                src="../../assets/images/open-eye.png"
                alt="user" class="responsive-image position-absolute"
                style="cursor: pointer;right: 5%; top: 50%; transform: translate(5%, -50%);">
                <img
                *ngIf="!showPassword"
                (click)='showHidePassword()'
                src="../../assets/images/close-eye.png"
                alt="user" class="responsive-image position-absolute"
                style="cursor: pointer;right: 5%; top: 50%; transform: translate(5%, -50%);">
            </div>
            <div *ngIf="submitted && f['confirmPassword'].errors">
              <div *ngIf="f['confirmPassword'].errors['required']" style="color: rgb(255, 126, 126);">
                *Password tidak boleh kosong
              </div>
              <div *ngIf="f['confirmPassword'].errors['matching']" style="color: rgb(255, 126, 126);">
                *Konfirmasi password tidak tidak sesuai
              </div>
            </div>
          </div>
          <div class="text-center mt-3" style="color: rgb(170, 170, 170);">
            Mohon untuk dicatat dan disimpan <span class="fw-bold">Username</span> dan <span class="fw-bold">Password</span><br>
            registrasi pendaftran santri baru masing-masing
          </div>
          <button type="submit"
            class="w-100 text-center mb-2 py-2 rounded-3 text-white fw-semibold"
            style="background: rgb(6, 149, 80); border: none; outline: none;">Daftar</button>
        </div>
      </form>
  </div>
</div>
