import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bookmark-icon',
  templateUrl: './BookmarkIcon.component.html',
})

export class BookmarkIconComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
