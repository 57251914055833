import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lock-icon',
  templateUrl: './LockIcon.component.html',
})

export class LockIconComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
