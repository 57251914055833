import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor() { }

  setCoreApiUrl(domain: String) : void {
    const apiUrl = environment.CORE_API_URL;
    const coreApiUrl = apiUrl.replace("$domain", domain.toLowerCase());
    localStorage.setItem("domain", domain.toLocaleLowerCase()); 
    localStorage.setItem("CORE_API_URL", coreApiUrl);
  }

  isSetDomain() : boolean {
    const currentDomain: any = localStorage.getItem("domain");

    console.log('current', currentDomain);
    if (!currentDomain || currentDomain === "" || currentDomain === "null") {
      return false;
    }

    return true;
  }

  currentDomain() : string {
    const svDomain: any = localStorage.getItem("domain");

    return svDomain?.toUpperCase();
  }

  getCoreApiUrl() : String {
    const coreApiUrl: any = localStorage.getItem("CORE_API_URL");

    return coreApiUrl;
  }
}
