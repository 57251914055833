import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthComponent } from './layout/auth/auth.component';
import { MainAppComponent } from './layout/main-app/main-app.component';
import { SidebarNavComponent } from './layout/sidebar-nav/sidebar-nav.component';
import { HomeComponent } from './home/home.component';
import { CoreService } from './service/core.service';
import { RegoffComponent } from './pages/regoff/regoff.component';
import { RegistrationComponent } from './registration/registration.component';
import { WINDOW_PROVIDERS } from './window.providers';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

import { BookmarkIconComponent } from './icons/BookmarkIcon.component';
import { GenderIconComponent } from './icons/GenderIcon.component';
import { HomeIconComponent } from './icons/HomeIcon.component';
import { LockIconComponent } from './icons/LockIcon.component';
import { MailIconComponent } from './icons/MailIcon.component';
import { PhoneIconComponent } from './icons/PhoneIcon.component';
import { UserIconComponent } from './icons/UserIcon.component';
import { UserPlusIconComponent } from './icons/UserPlusIcon.component';
import { DashboardTkComponent } from './pages/dashboard-tk/dashboard-tk.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SidebarNavComponent,
    AuthComponent,
    MainAppComponent,
    HomeComponent,
    RegoffComponent,
    RegistrationComponent,
    SafeHtmlPipe,
    BookmarkIconComponent,
    GenderIconComponent,
    HomeIconComponent,
    LockIconComponent,
    MailIconComponent,
    PhoneIconComponent,
    UserIconComponent,
    UserPlusIconComponent,
    DashboardTkComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide : HTTP_INTERCEPTORS,
      useClass: CoreService,
      multi   : true,
    },
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
