<!-- Modal -->
<div class="modal fade" id="regoffNotif" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel"></h5>        
    </div>
    <div class="modal-body">
        Mohon maaf saat ini pendaftaran siswa baru di Darunnajah pusat Ulujami Jakarta belum dibuka. Untuk informasi lebih lanjut hubungi:
    </div>
    <div class="modal-footer d-flex justify-content-center">        
        <button type="button" class="btn btn-primary" (click)="logout()">Logout</button>
    </div>
    </div>
</div>
</div>