import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-icon',
  templateUrl: './UserIcon.component.html',
})

export class UserIconComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
