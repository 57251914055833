import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

let CORE_API: String;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CoreService implements HttpInterceptor {

  constructor(private http: HttpClient, private networkService: NetworkService, private authService: AuthService) {
    CORE_API = this.networkService.getCoreApiUrl();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if account is logged in and request is to the api url
    const isLoggedIn = this.authService.isUserLoggedIn();
    const token = this.authService.userToken();
    console.log('isLogedIn', isLoggedIn, token);
    if (isLoggedIn) {
        request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
        });
    }

    return next.handle(request);
  }

  private _getRequest(url: string) {
    CORE_API = this.networkService.getCoreApiUrl();
    return this.http.get(CORE_API + url);
  }

  getAllLembaga(params: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    return this.http.get(`${CORE_API}/v1/lembaga`, { 'params': params, headers: httpOptions.headers, withCredentials: false } );
  }

  getTingkatByLembagaId(id: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    return this.http.get(`${CORE_API}/v1/tingkatbylembaga/${id}`);
  }

  getOpenRegistration(): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    return this._getRequest('/v1/getactivereg');
  }

  getRegistrant(id: string): Observable<any> {
    return this._getRequest('/v1/registrant/' + id);
  }

  saveReg(username: string, password: string): Observable<any> {
    console.log("API", CORE_API)
    return this.http.post(CORE_API + '/v1/registrant/login', {
      username,
      password
    }, httpOptions);
  }

  getCalonSiswa(payload: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    console.log("API CORE", CORE_API)
    return this.http.post(`${CORE_API}/v1/getsiswabyregistrant`, payload, httpOptions);
  }

  getLokasiUjian(): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    return this._getRequest('/v1/lokasiujianaktif');
  }

  getCalonSiswaDokumen(id: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    return this._getRequest('/v1/getSiswaBerkas/' + id);
  }

  getContentRegisByType(type: string): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    return this._getRequest('/v1/contentregisGetByType/' + type);
  }

  updateCalonSiswa(id: any, payload: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    console.log("API CORE", CORE_API)
    return this.http.put(`${CORE_API}/v1/siswa/${id}`, payload, httpOptions);
  }

  getBankByKode(payload: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    console.log("API CORE", CORE_API)
    return this.http.post(`${CORE_API}/v1/getBankByKode`, payload, httpOptions);
  }

  createbiayaformulir(payload: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    console.log("API CORE", CORE_API)
    return this.http.post(`${CORE_API}/v1/createbiayaformulir`, payload, httpOptions);
  }

  getTagihanFormulir(payload: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    console.log("API CORE", CORE_API)
    return this.http.post(`${CORE_API}/v1/getTagihanFormulir`, payload, httpOptions);
  }

  saveVaNumber(payload: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    console.log("API CORE", CORE_API)
    return this.http.post(`${CORE_API}/v1/createregistrationva`, payload, httpOptions);
  }

  changeStep(payload: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    console.log("API CORE", CORE_API)
    return this.http.put(`${CORE_API}/v1/siswa/updateRegStep`, payload, httpOptions);
  }

  changeInvoiceStatus(payload: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    console.log("API CORE", CORE_API)
    return this.http.post(`${CORE_API}/v1/updateinvoicestatus`, payload, httpOptions);
  }

  upload(file: File, tipe: string): Observable<HttpEvent<any>> {
    CORE_API = this.networkService.getCoreApiUrl();
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${CORE_API}/v1/upload?type=${tipe}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  removeFile(name: string): Observable<any> {
    console.log('Delete the file', name);
    return this._getRequest('/v1/removeberkasfile/' + name);
  }

  removePhotoSiswa(name: string): Observable<any> {
    console.log('Delete the file', name);
    return this._getRequest('/v1/removesiswafile/' + name);
  }

  saveBerkas(payload: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    console.log("API CORE", CORE_API)
    return this.http.post(`${CORE_API}/v1/dokumensyarat`, payload, httpOptions);
  }

  savePhotoSiswa(payload: any): Observable<any> {
    CORE_API = this.networkService.getCoreApiUrl();
    console.log("API CORE", CORE_API)
    return this.http.put(`${CORE_API}/v1/siswa/updatePhotoSiswa`, payload, httpOptions);
  }

  getCurrentBiayaRegistrasi(idperiode: any): Observable<any> {
    return this._getRequest('/v1/biayaregistrasi/0/' + idperiode);
  }

  getBerkasUrl(f: string) {
    const domain = this.networkService.currentDomain();
    return `${environment.IMAGE_URL}/${domain}/berkas/${f}`; 
  }

  getSiswaFileUrl(f: string) {
    const domain = this.networkService.currentDomain().toLowerCase();
    return `${environment.IMAGE_URL}/${domain}/siswa/${f}`; 
  }

}
