import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UiService } from 'src/app/helper/ui.service';

@Component({
  selector: 'app-regoff',
  templateUrl: './regoff.component.html',
  styleUrls: ['./regoff.component.css']
})
export class RegoffComponent implements OnInit {

  constructor(private uiService: UiService, private router: Router) { }

  ngOnInit(): void {
    this.uiService.showModalDialog('regoffNotif');
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();

    this.router.navigate(['home']);
    window.location.reload();
  }

}
