import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NetworkService } from '../helper/network.service';

let AUTH_API: String = "http://api.local-darunnajah";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private netService: NetworkService) { }

  setUrlApi(): void {

    AUTH_API = this.netService.getCoreApiUrl();

  }
  
  isUserLoggedIn() {
    return !!localStorage.getItem('token')
  }

  userToken() {
    return localStorage.getItem('token');
  }

  login(username: string, password: string): Observable<any> {
    
    console.log("API", AUTH_API)
    return this.http.post(AUTH_API + '/v1/registrant/login', {
      username,
      password
    }, httpOptions);
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + '/v1/registrant/register', {
      username,
      email,
      password
    }, httpOptions);
  }

  getUserLoginData() : any {
    const encUser: any = localStorage.getItem("registrant");
    const user: any = JSON.parse(atob(encUser));
    console.log(user.iduser); 

    return user;
  }

}
