import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-plus-icon',
  templateUrl: './UserPlusIcon.component.html',
})

export class UserPlusIconComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
