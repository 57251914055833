import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { UiService } from 'src/app/helper/ui.service';
import { CoreService } from 'src/app/service/core.service';
import { GeneralService } from 'src/app/service/general.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpParams, HttpEventType, HttpResponse } from '@angular/common/http';
import * as moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/helper/common.service';

declare var Datepicker: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild("examCard") examCard: ElementRef | undefined;

  openRegistration: any;
  userData: any;
  calonSiswa: any;
  bank: any;
  currentVaNumber: String = ""
  currentInvoice: String = ""
  currentStep: String = ""
  currentInstansi:any = null;
  currentLembaga = ""
  currentTingkat = ""
  currentIdPeriode = 0;
  errorMessage = ""
  editRegistrationData:boolean = false;
  isEditDocumentValidation:boolean = false;
  currentBiaya: any;
  idgroupbiaya: any;
  namaGroupBiaya: String = "";
  suratPernyataanUrl: string = "";
  imgSrc: String = "";

  showStep1:boolean = false;
  showStep2:boolean = false;
  showStep3:boolean = false;
  showStep4:boolean = false;
  showStep5:boolean = false;
  showStep6:boolean = false;
  showStep7:boolean = false;
  showStep8:boolean = false;
  showStep9:boolean = false;

  showDoneStep1:boolean = true;
  showDoneStep2:boolean = false;
  showDoneStep3:boolean = false;
  showDoneStep4:boolean = false;
  showDoneStep5:boolean = false;
  showDoneStep6:boolean = false;
  showDoneStep7:boolean = false;
  showDoneStep8:boolean = false;
  showDoneStep9:boolean = false;

  listsCountry: any = [];
  listsProvinsi: any = [];
  listsKabupaten: any = [];
  listsKecamatan: any = [];
  listsKelurahan: any = [];

  listLokasiUjian: any = [];
  listSiswaDokumen: any = [];
  contentRegis: string = "";

  selectedFiles?: FileList;
  currentFile?: File;
  currentBerkas:any = null;
  currentSaveFileName = "";
  progress = 0;

  tanggalList: any = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
  bulanList: any = [
    {month:1, name:"Januari"},
    {month:2, name:"Februari"},
    {month:3, name:"Maret"},
    {month:4, name:"April"},
    {month:5, name:"Mei"},
    {month:6, name:"Juni"},
    {month:7, name:"Juli"},
    {month:8, name:"Agustus"},
    {month:9, name:"September"},
    {month:10, name:"Oktober"},
    {month:11, name:"November"},
    {month:12, name:"Desember"},
  ]

  tahunList:any =  []

  showPopup: Boolean = false;
  showInfo: boolean = false;

  formSiswa: FormGroup = new FormGroup({
    nisn: new FormControl(''),
    npsn: new FormControl(''),
    nss: new FormControl(''),
    no_ijazah: new FormControl(''),
    nama_lengkap: new FormControl(''),
    nik: new FormControl(''),
    pob: new FormControl(''),
    dob: new FormControl(''),
    anak_ke: new FormControl(''),
    jumlah_anak: new FormControl(''),
    telepon: new FormControl(''),
    riwayat_penyakit: new FormControl(''),
    berat_badan: new FormControl(''),
    tinggi_badan: new FormControl(''),
    golongan_darah: new FormControl(''),
    hoby: new FormControl(''),
    ukuran_baju: new FormControl(''),
    email: new FormControl(''),
    saudara_kandung_aktif: new FormControl(''),
    sumber_info_sekolah: new FormControl(''),
    gender: new FormControl(''),
    inputTglLulus: new FormControl(''),
    inputBulanLulus: new FormControl(''),
    inputTahunLulus: new FormControl(''),
    asal_sekolah: new FormControl(''),
    nama_sekolah_asal: new FormControl(''),
    tanggal_lulus: new FormControl(''),
    // Ayah
    no_kk: new FormControl(''),
    nama_ayah: new FormControl(''),
    nik_ayah: new FormControl(''),
    pekerjaan_ayah: new FormControl(''),
    status_ayah: new FormControl(''),
    pob_ayah: new FormControl(''),
    dob_ayah: new FormControl(''),
    inputTglAyah: new FormControl(''),
    inputBulanAyah: new FormControl(''),
    inputTahunAyah: new FormControl(''),
    gelar_akademik_ayah: new FormControl(''),
    status_hidup_ayah: new FormControl(''), //DataTypes.ENUM("HIDUP","MENINGGAL"),
    pendidikan_terkahir_ayah: new FormControl(''),
    penghasilan_perbulan_ayah: new FormControl(''),
    telp_ayah: new FormControl(''),
    alamat_ayah: new FormControl(''),
    //Ibu
    nama_ibu: new FormControl(''),
    nik_ibu: new FormControl(''),
    status_ibu: new FormControl(''),
    pob_ibu: new FormControl(''),
    dob_ibu: new FormControl(''),
    inputTglIbu: new FormControl(''),
    inputBulanIbu: new FormControl(''),
    inputTahunIbu: new FormControl(''),
    gelar_akademik_ibu: new FormControl(''),
    status_hidup_ibu: new FormControl(''), //DataTypes.ENUM("HIDUP","MENINGGAL"),
    pendidikan_terkahir_ibu: new FormControl(''),
    pekerjaan_ibu: new FormControl(''),
    penghasilan_perbulan_ibu: new FormControl(''),
    telp_ibu: new FormControl(''),
    alamat_ibu: new FormControl(''),

    //ALamat
    alamat: new FormControl(''),
    rt: new FormControl(''),
    rw: new FormControl(''),
    provinsi: new FormControl(''),
    kodepos: new FormControl(''),
    kota_kab: new FormControl(''),
    kecamatan: new FormControl(''),
    desa_kelurahan: new FormControl(''),
    lokasi_ujian: new FormControl(''),

    // penanggung
    yang_menanggung_biaya: new FormControl(''),
    nama_penanggung_biaya: new FormControl(''),
    hubungan_keluarga_penanggung_biaya: new FormControl(''),
    telepon_penanggung_biaya: new FormControl(''),
    pekerjaan_penanggung_biaya: new FormControl(''),
    alamat_penanggung_biaya: new FormControl(''),
    kewarganegaraan: new FormControl(''),
    negara: new FormControl('')

    // nama_arab: new FormControl(''),
    // nama_ayah: new FormControl(''),
    // nama_ibu: new FormControl(''),
    // tahun_masuk: new FormControl(''),
    // lembaga_tujuan: new FormControl(''),
    // tingkat_id: new FormControl(''),
    // username: new FormControl(''),
    // password: new FormControl(''),

    // ukuran_baju: new FormControl(''),

    // alamat: new FormControl(''),
    // provinsi: new FormControl(''),
    // kota_kab: new FormControl(''),
    // kecamatan: new FormControl(''),
    // desa_kelurahan: new FormControl(''),
    // rt: new FormControl(''),
    // rw: new FormControl(''),

    // admin_input: new FormControl(''),
  });
  submitted = false

  constructor(private coreService: CoreService, private generalService: GeneralService,
    private uiService: UiService, private scroller: ViewportScroller, private commonService: CommonService,
    private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private elementRef: ElementRef) {
  }

  async ngOnInit(): Promise<void> {

    const instansi = localStorage.getItem('instansi');
    const instansiType = this.commonService.getInstansiTypeByInstansiName(instansi);
    if (instansiType === "TK") {
      this.router.navigate(['dashboard-tk']);
      return;
    }

    this.listsCountry = this.commonService.getAllCountries();
    this.userData = this.authService.getUserLoginData();
    console.log(this.userData);
    this.currentIdPeriode = this.userData.Registrant.id_periode;
    await this.getBankDetail();
    await this.getCurrentBiayaRegistrasi();
    await this.getDetailInstansi();
    await this.getDetailCalonSiswa();
    await this.getLokasiUjian();
    this.initForm();
    this.tahunList = this.uiService.getTahunList();
  }

  getBankDetail() {
    const bankPayload = {
      kode: "BSI"
    }

    this.coreService.getBankByKode(bankPayload).subscribe({
      next: data => {
        console.log("BANK", data);
        this.bank = data;
      },
      error: err => {
        this.logout();
      }
    })
  }

  ngAfterViewInit() {
    this.configureDatePicker();
  }

  printCard(e: Event) {
    e.stopImmediatePropagation();

    let DATA: any = document.getElementById('kartuRegistrasi');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');

      let a = window.open('', '');
      a?.document.write('<html>');
      a?.document.write('<body onload="window.print();">');
      a?.document.write('<img src="'+FILEURI+'" />');
      a?.document.write('</body></html>');
      a?.document.close();
    });
  }

  ngAfterViewChecked() {
    if (this.elementRef.nativeElement.querySelector("#printCardButton")) {
      this.elementRef.nativeElement
        .querySelector("#printCardButton")
        .addEventListener("click", this.printCard.bind(this));
    }
  }

  selectFile(event: any, berkas: any): void {
    this.currentBerkas = berkas;
    this.selectedFiles = event.target.files;
    console.log("FILE :", berkas, this.selectedFiles);
    // langsung upload
    this.upload();
  }

  upload(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.coreService.upload(this.currentFile, 'berkas').subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.uiService.showSuccess(event.body.message);
              this.currentSaveFileName = event.body.filename;

              this.saveBerkas(this.currentBerkas);
              //this.fileInfos = this.uploadService.getFiles();
            }
          },
          error: (err: any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.uiService.showError(err.error.message);
            } else {
              this.uiService.showError('Could not upload the file!');
            }

            this.currentFile = undefined;
            this.currentBerkas = null;
          }
        });
      }

      this.selectedFiles = undefined;
    }
  }

  saveBerkas(berkas: any) {
    // delete previose file
    if (this.currentBerkas.dokumen != null) {
      console.log("DELETE ", this.currentBerkas.dokumen.file_url);
      this.coreService.removeFile(this.currentBerkas.dokumen.file_url).subscribe({
        next: (event: any) => {
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
    this.currentBerkas = null;
    this.uiService.showLoader();

    const payload = {
      "id_siswa": this.calonSiswa.id,
      "berkas_id": berkas.id,
      "urut": berkas.urut,
      "nama_dokumen": berkas.nama_dokumen,
      "file_url": this.currentSaveFileName,
    }
    this.coreService.saveBerkas(payload).subscribe({
      next: data => {
        console.log(data);
        this.getSiswaDokumen(this.calonSiswa.id);
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  savePhotoSiswa(photo: any) {
    // delete previose file
    if (this.calonSiswa.photo != null) {
      console.log("DELETE ", this.calonSiswa.photo);
      this.coreService.removePhotoSiswa(this.calonSiswa.photo).subscribe({
        next: (event: any) => {
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
    this.uiService.showLoader();

    const payload = {
      "id": this.calonSiswa.id,
      "photo": photo,
    }
    this.coreService.savePhotoSiswa(payload).subscribe({
      next: data => {
        console.log("AFTER UPLOAD", data);
        this.getDetailCalonSiswa();
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  initForm() {
    this.formSiswa = this.formBuilder.group(
      {
        nisn: ['', [Validators.required]],
        npsn: ['', Validators.required],
        nss: [''],
        no_ijazah: [''],
        nama_lengkap: ['', Validators.required],
        nik: ['', Validators.required],
        pob: ['', Validators.required],
        dob: ['', Validators.required],
        anak_ke: ['', Validators.required],
        jumlah_anak: ['', Validators.required],
        telepon: ['', Validators.required],
        riwayat_penyakit: [''],
        berat_badan: [''],
        tinggi_badan: [''],
        golongan_darah: ['', Validators.required],
        hoby: [''],
        ukuran_baju: [Validators.required],
        email: ['', Validators.required],
        saudara_kandung_aktif: [''],
        sumber_info_sekolah: [''],
        gender: ['', Validators.required],
        // inputTglLulus: ['', Validators.required],
        // inputBulanLulus: ['', Validators.required],
        // inputTahunLulus: ['', Validators.required],
        inputTglLulus: [''],
        inputBulanLulus: [''],
        inputTahunLulus: [''],
        asal_sekolah: ['', Validators.required],
        nama_sekolah_asal: ['', Validators.required],
        tanggal_lulus: [''], // :TODO tanggal_lulus: ['', Validators.required],
        // Ayah
        no_kk: ['', Validators.required],
        nama_ayah: ['', Validators.required],
        nik_ayah: ['', Validators.required],
        pekerjaan_ayah: ['', Validators.required],
        status_ayah: ['', Validators.required],
        pob_ayah: ['', Validators.required],
        inputTglAyah: ['', Validators.required],
        inputBulanAyah: ['', Validators.required],
        inputTahunAyah: ['', Validators.required],
        dob_ayah: [''], // TODO dob_ayah: ['', Validators.required],
        gelar_akademik_ayah: ['', Validators.required],
        status_hidup_ayah: ['', Validators.required], //DataTypes.ENUM("HIDUP","MENINGGAL"),
        pendidikan_terkahir_ayah: ['', Validators.required],
        penghasilan_perbulan_ayah: ['', Validators.required],
        telp_ayah: ['', Validators.required],
        alamat_ayah: ['', Validators.required],
        //Ibu
        nama_ibu: ['', Validators.required],
        nik_ibu: ['', Validators.required],
        status_ibu: ['', Validators.required],
        pob_ibu: ['', Validators.required],
        dob_ibu: [''], // TODO dob_ibu: ['', Validators.required],
        inputTglIbu: ['', Validators.required],
        inputBulanIbu: ['', Validators.required],
        inputTahunIbu: ['', Validators.required],
        gelar_akademik_ibu: ['', Validators.required],
        status_hidup_ibu: ['', Validators.required], //DataTypes.ENUM("HIDUP","MENINGGAL"),
        pendidikan_terkahir_ibu: ['', Validators.required],
        pekerjaan_ibu: ['', Validators.required],
        penghasilan_perbulan_ibu: ['', Validators.required],
        telp_ibu: ['', Validators.required],
        alamat_ibu: ['', Validators.required],
        // Alamat
        alamat: ['', Validators.required],
        rt: ['', Validators.required],
        rw: ['', Validators.required],
        provinsi: ['', Validators.required],
        kodepos: [''],
        kota_kab: ['', Validators.required],
        kecamatan: ['', Validators.required],
        desa_kelurahan: ['', Validators.required],
        lokasi_ujian: [''],

        // penanggung
        yang_menanggung_biaya: ['', Validators.required],
        nama_penanggung_biaya: ['', Validators.required],
        hubungan_keluarga_penanggung_biaya: ['', Validators.required],
        telepon_penanggung_biaya: ['', Validators.required],
        pekerjaan_penanggung_biaya: ['', Validators.required],
        alamat_penanggung_biaya: ['', Validators.required],
        kewarganegaraan: [''],
        negara: ['']

        // nama_arab: [''],
        // nama_ayah: ['', Validators.required],
        // nama_ibu: ['', Validators.required],
        // tahun_masuk: ['', Validators.required],
        // lembaga_tujuan: ['', Validators.required],
        // tingkat_id: ['', Validators.required],
        // username: [''],
        // password: [''],

        // ukuran_baju: ['', Validators.required],

        // alamat: ['', Validators.required],
        // provinsi: ['', Validators.required],
        // kota_kab: ['', Validators.required],
        // kecamatan: ['', Validators.required],
        // desa_kelurahan: ['', Validators.required],
        // rt: ['', Validators.required],
        // rw: ['', Validators.required],

        // admin_input: [''],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formSiswa.controls;
  }

  getLokasiUjian() {
    this.coreService.getLokasiUjian().subscribe({
      next: data => {
        console.log("LOKASI UJIAN", data);
        this.listLokasiUjian = data;
        this.uiService.hideLoader();
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  getProvinsi() {
    this.uiService.showLoader();

    this.generalService.getAllProvinsi().subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsProvinsi = resdata;


          const provinsi = this.calonSiswa.provinsi;
          const kabupaten = this.calonSiswa.kota_kab;

          this.formSiswa.controls['provinsi'].setValue(provinsi);
          this.getKabupatenProvinsi(provinsi, kabupaten);


        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKabupaten(e: any) {
    console.log(e.target.value);
    this.getKabupatenProvinsi(e.target.value);
  }

  private getKabupatenProvinsi(provinsi: any, curentKabupaten: any = null) {
    let params = new HttpParams();
    params = params.append("provinsi", provinsi);

    this.uiService.showLoader();

    this.generalService.getKabupatenByProvinsi(params).subscribe({
      next: resdata => {
        console.log(resdata);
        this.listsKecamatan = [];
        this.listsKelurahan = [];
        this.listsKabupaten = resdata;
        console.log('value', this.formSiswa.value.kota_kab);
        if (curentKabupaten !== null) {
          this.formSiswa.controls['kota_kab'].setValue(curentKabupaten);
          this.getKecamatanKabupatenProvonsi(provinsi, curentKabupaten, this.calonSiswa.kecamatan);
        } else {
          this.formSiswa.controls['kota_kab'].setValue('');
        }
        this.formSiswa.controls['kecamatan'].setValue('');
        this.formSiswa.controls['desa_kelurahan'].setValue('');

        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeKecamatan(e: any) {
    console.log(e.target.value);
    const provinsi = this.formSiswa.value.provinsi;
    const kabupaten = e.target.value;

    this.getKecamatanKabupatenProvonsi(provinsi, kabupaten);
  }

  private getKecamatanKabupatenProvonsi(provinsi: any, kabupaten: any, kecamatan: any = null) {
    this.uiService.showLoader();

    let params = new HttpParams();

    params = params.append("provinsi", provinsi);
    params = params.append("kabupaten", kabupaten);

    this.generalService.getKecamatanByKabupatenProvinsi(params).subscribe({
      next: resdata => {
        console.log('kecamatan', resdata);
        this.listsKelurahan = [];
        if (kecamatan !== null) {
          this.formSiswa.controls['kecamatan'].setValue(kecamatan);
          this.getKelurahanKecamatanKabupatenProvinsi(provinsi, kabupaten, kecamatan, this.calonSiswa.desa_kelurahan);
        } else {
          this.formSiswa.controls['kecamatan'].setValue('');
        }
        this.formSiswa.controls['desa_kelurahan'].setValue('');
        this.listsKecamatan = resdata;

        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });

  }

  changeKelurahan(e: any) {
    console.log(e.target.value);
    const provinsi = this.formSiswa.value.provinsi;
    const kabupaten = this.formSiswa.value.kota_kab;
    const kecamatan = e.target.value;

    this.getKelurahanKecamatanKabupatenProvinsi(provinsi,kabupaten,kecamatan);
  }

  private getKelurahanKecamatanKabupatenProvinsi(provinsi: any, kabupaten: any, kecamatan: any, kelurahan: any = null) {
    this.uiService.showLoader();

    let params = new HttpParams();

    params = params.append("provinsi", provinsi);
    params = params.append("kabupaten", kabupaten);
    params = params.append("kecamatan", kecamatan);

    this.generalService.getKelurahanByKecamatanKabupatenProvinsi(params).subscribe({
      next: resdata => {
        this.listsKelurahan = resdata;
        console.log('kelurahan', resdata);
        if (kelurahan !== null) {
          this.formSiswa.controls['desa_kelurahan'].setValue(kelurahan);
        } else {
          this.formSiswa.controls['desa_kelurahan'].setValue('');
        }

        this.uiService.hideLoader();
      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  closePopup() {
    this.showPopup = false;
  }

  configureDatePicker() {
    setTimeout(() => {
      const elem = document.getElementById('birtdhdate');
      if (elem == null) {   //  or undefined - adjust accordingly
        //
      } else {
        console.log('bdate', elem);
        const datepicker = new Datepicker(elem, {
          clearBtn: true,
          orientation: 'auto',
          autohide: false,
          format: 'yyyy-mm-dd',
        });
      }
    }, 500)

  }

  changeShowRegStatus(reg1:boolean,reg2:boolean,reg3:boolean,reg4:boolean,reg5:boolean,reg6:boolean,reg7:boolean,) {
    this.showStep1 = reg1;
    this.showStep2 = reg2;
    this.showStep3 = reg3;
    this.showStep4 = reg4;
    this.showStep5 = reg5;
    this.showStep6 = reg6;
    this.showStep7 = reg7;
  }

  changeShowDoneRegStatus(reg1:boolean,reg2:boolean,reg3:boolean,reg4:boolean,reg5:boolean,reg6:boolean,reg7:boolean,) {
    this.showDoneStep1 = reg1;
    this.showDoneStep2 = reg2;
    this.showDoneStep3 = reg3;
    this.showDoneStep4 = reg4;
    this.showDoneStep5 = reg5;
    this.showDoneStep6 = reg6;
    this.showDoneStep7 = reg7;
  }

  changeRegStepToPayRegister() {

    this.uiService.showLoader();

    const payload = {
      "id": this.calonSiswa.id,
      "step": "REGISTRATION_FEE"
    }
    this.coreService.changeStep(payload).subscribe({
      next: data => {
        console.log(data);

        this.showInfo = false;
        this.currentStep = "REGISTRATION_FEE";

        this.changeShowRegStatus(false,true,false,false,false,false,false);
        this.changeShowDoneRegStatus(true,false,false,false,false,false,false);

        this.gotoSection("bayarUlang");
        this.uiService.hideLoader();
        this.getDetailCalonSiswa();
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  changeRegStepToExamInformation() {

    // validasi doc
    let isValid = true;
    for (let i=0; i<this.listSiswaDokumen.length; i++) {
      if (this.listSiswaDokumen[i].mandatory === true && this.listSiswaDokumen[i].dokumen == null) {
        this.uiService.showError("Mohon unggah semua dokumen");
        isValid = false;
        break;
      }
    }

    if (isValid) {
      this.currentStep = "ENTRANCE_EXAMINATION";

      this.uiService.showLoader();

      const payload = {
        "id": this.calonSiswa.id,
        "step": "ENTRANCE_EXAMINATION"
      }
      this.coreService.changeStep(payload).subscribe({
        next: data => {
          console.log(data);

          this.currentStep = "ENTRANCE_EXAMINATION";

          this.changeShowRegStatus(false,false,false,false,true,false,false);
          this.changeShowDoneRegStatus(true,true,true,true,false,false,false);

          this.gotoSection("bayarUlang");
          this.uiService.hideLoader();
          this.getDetailCalonSiswa();
        },
        error: err => {
          const errorMessage = err.error.message;

          this.uiService.hideLoader();

          this.uiService.showError(errorMessage);
        }
      });
    }
  }

  goToPayRegistrationPage() {
    this.changeRegStepToPayRegister();
  }

  changeRegStepToFillData() {
    this.uiService.showLoader();

    const payload = {
      "id": this.calonSiswa.id,
      "step": "FILL_REGISTRATION_DATA"
    }
    this.coreService.changeStep(payload).subscribe({
      next: data => {
        console.log(data);
        this.currentStep = "FILL_REGISTRATION_DATA";
        this.gotoSection("bayarUlang");
        this.uiService.hideLoader();
        this.getDetailCalonSiswa();
        this.uiService.showSuccess("Pembayaran berhasil, lanjutkan ke pengisian.");
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  changeRegStepToFillDocument() {
    this.uiService.showLoader();

    const payload = {
      "id": this.calonSiswa.id,
      "step": "DOCUMENT_VALIDATION"
    }
    this.coreService.changeStep(payload).subscribe({
      next: data => {
        console.log(data);
        this.currentStep = "DOCUMENT_VALIDATION";
        this.getSiswaDokumen(data.id);
        this.changeShowRegStatus(false,false,false,true,false,false,false);
        this.changeShowDoneRegStatus(true,true,true,false,false,false,false);
        this.getTahapanContent('TAHAP_4');
        this.gotoSection("bayarUlang");
        this.uiService.hideLoader();
        this.uiService.showSuccess("Pengisian berhasil, lanjutkan ke validasi dokumen.");
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  editFillRegistration() {
    this.editRegistrationData = true;
    this.currentStep = "FILL_REGISTRATION_DATA";
    this.changeShowRegStatus(false,false,true,false,false,false,false);
    this.changeShowDoneRegStatus(true,true,false,false,false,false,false);
    this.getProvinsi();
    console.log('SISWA', this.calonSiswa.tanggal_lulus);
    
    if (this.calonSiswa.kewarganegaraan === "WNA") {
      this.setValidatorWna();
    }
    
    if (this.calonSiswa.tanggal_lulus !== null) {
      const tglLulus =  this.setFormatDob(this.calonSiswa.tanggal_lulus);
      const monthLulus = tglLulus.month() + 1;
      this.formSiswa.controls['inputTglLulus'].setValue(tglLulus.date());
      this.formSiswa.controls['inputBulanLulus'].setValue(monthLulus);
      this.formSiswa.controls['inputTahunLulus'].setValue(tglLulus.year());
    }

    const dobAyah =  this.setFormatDob(this.calonSiswa.dob_ayah);
    const monthAyah = dobAyah.month() + 1;
    this.formSiswa.controls['inputTglAyah'].setValue(dobAyah.date());
    this.formSiswa.controls['inputBulanAyah'].setValue(monthAyah);
    this.formSiswa.controls['inputTahunAyah'].setValue(dobAyah.year());

    const dobIbu =  this.setFormatDob(this.calonSiswa.dob_ibu);
    const monthIbu = dobIbu.month() + 1;
    this.formSiswa.controls['inputTglIbu'].setValue(dobIbu.date());
    this.formSiswa.controls['inputBulanIbu'].setValue(monthIbu);
    this.formSiswa.controls['inputTahunIbu'].setValue(dobIbu.year());

    this.formSiswa.patchValue(this.calonSiswa);

    const dobSiswa = moment(this.calonSiswa.dob).format('YYYY-MM-DD');
    this.formSiswa.controls['dob'].setValue(dobSiswa);
  }

  editDocumentValidation() {
    this.isEditDocumentValidation = true;
    this.currentStep = "DOCUMENT_VALIDATION";
    this.getSiswaDokumen(this.calonSiswa.id);
    this.changeShowRegStatus(false,false,false,true,false,false,false);
    this.changeShowDoneRegStatus(true,true,true,false,false,false,false);
    this.getTahapanContent('TAHAP_4');
    this.gotoSection("bayarUlang");
  }

  changeDobField(e: any) {
    console.log(e.target.value);
    const birthdate = e.target.value;
    this.formSiswa.controls['dob'].setValue(birthdate);
  }

  gotoSection(section: any = "") {
    console.log(section);
    this.scroller.scrollToAnchor(section);
  }

  getTahapanContent(type: string) {
    this.uiService.showLoader();

    this.coreService.getContentRegisByType(type).subscribe({
      next: data => {
        console.log('CONTENT', data);

        if (data) {
          if (type === 'TAHAP_8') {
            let str = data.body;
            str = str.replace(/{{ namaSiswa }}/g, this.calonSiswa.nama_lengkap);
            str = str.replace(/{{ noIndukSiswa }}/g, this.calonSiswa.no_induk);

            this.contentRegis = str
          } else {
            this.contentRegis = data.body;
          }
          this.uiService.hideLoader();
        }
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  getCurrentBiayaRegistrasi() {
    this.uiService.showLoader();

    this.coreService.getCurrentBiayaRegistrasi(this.currentIdPeriode).subscribe({
      next: data => {
        console.log('BIAYA', data);
        if (data) {
          this.currentBiaya = data[0].total_group_biaya;
          this.idgroupbiaya = data[0].group_biaya_id;
          this.namaGroupBiaya = data[0].name; 
          this.uiService.hideLoader();
        }
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  getDetailInstansi() {
    this.uiService.showLoader();

    this.generalService.getCurrentInstansi().subscribe({
      next: data => {
        console.log("INSTANSI", data);
        this.currentInstansi = data;
        this.suratPernyataanUrl = "cdn/" + this.currentInstansi.kode_instansi + "/surat_pernyataan_2023-2024.pdf";

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  getDetailCalonSiswa() {
    this.uiService.showLoader();
    const payload = {
      "registrant_id": this.userData.Registrant.id
    }
    this.coreService.getCalonSiswa(payload).subscribe({
      next: data => {
        console.log('DATA SISWA', data);
        if (data) {
          this.calonSiswa = data;
          this.currentLembaga = data.lembaga.nama_lembaga;
          this.currentTingkat = data.tingkat.nama_tingkat;

          if (this.calonSiswa.photo !== null) {
            this.imgSrc = this.coreService.getSiswaFileUrl(this.calonSiswa.photo);
          }

          // enable disable popup
          if (data.current_status === "REGISTRATION") {
            this.getTahapanContent('TAHAP_1');
            this.showInfo = true;
            this.showStep1 = true;
          } else if (data.current_status === "REGISTRATION_FEE") {            
            this.getTahapanContent('TAHAP_2');
            this.changeShowRegStatus(false,true,false,false,false,false,false);
            this.changeShowDoneRegStatus(true,false,false,false,false,false,false);
          } else if (data.current_status === "FILL_REGISTRATION_DATA") {
            this.changeShowRegStatus(false,false,true,false,false,false,false);
            this.changeShowDoneRegStatus(true,true,false,false,false,false,false);
            this.getProvinsi();
            this.formSiswa.patchValue(data);
          } else if (data.current_status === "DOCUMENT_VALIDATION") {
            this.getTahapanContent('TAHAP_4');
            this.getSiswaDokumen(data.id);
            this.changeShowRegStatus(false,false,false,true,false,false,false);
            this.changeShowDoneRegStatus(true,true,true,false,false,false,false);
          } else if (data.current_status === "ENTRANCE_EXAMINATION") {
            this.getTahapanContent('TAHAP_5');
            this.changeShowRegStatus(false,false,false,false,true,false,false);
            this.changeShowDoneRegStatus(true,true,true,true,false,false,false);
          } else if (data.current_status === "ENTRY_TUITION_FEE") {
            this.getTahapanContent('TAHAP_6A');
            this.changeShowRegStatus(false,false,false,false,false,true,false);
            this.changeShowDoneRegStatus(true,true,true,true,true,false,false);
          } else if (data.current_status === "INFORMATION") {
            this.getTahapanContent('TAHAP_8');
            this.changeShowRegStatus(false,false,false,false,false,false,true);
            this.changeShowDoneRegStatus(true,true,true,true,true,true,false);
          }

          // cek registration VA
          this.currentStep = data.current_status;

          // if (data.current_status === "FILL_REGISTRATION_DATA") {
          //   this.getProvinsi();
          //   this.formSiswa.patchValue(data);
          // }

          if (data.current_status === "REGISTRATION_FEE") {
            // generate VA
            // dummy for a while

            // 1. check if tagihan is lunas or not

            const tagihanPayload = {
              idsiswa: this.calonSiswa.id
            }
            
            
            this.coreService.getTagihanFormulir(tagihanPayload).subscribe({
              next: invoice => {
                console.log("DATA INVOICE1", this.currentBiaya, invoice);
                if (invoice === null) {
                  this.uiService.showLoader();
                  const biayaPayload = {
                    amount: this.currentBiaya,
                    idgroupbiaya: this.idgroupbiaya,
                    namaGroupBiaya: this.namaGroupBiaya,
                    idsiswa: this.calonSiswa.id,
                    idbank: this.bank.id
                  }
                  this.coreService.createbiayaformulir(biayaPayload).subscribe({
                    next: data => {
                      this.uiService.hideLoader();
                      console.log("DATA FORMULIR", data);
                      this.currentVaNumber = this.bank.prefix + data.Data.AccountNumber;
                    },
                    error: err => {
                      this.uiService.hideLoader();
                      this.uiService.showError("Terjadi kesalahan pembayaran formulir, Silahkan menghubungi admin");
                      this.logout();
                    }
                  })

                } else {
                  if (invoice.status_pembayaran === "LUNAS") {
                    this.uiService.showSuccess("Pembayaran formulir sukses");
                    this.changeRegStepToFillData();
                  }

                  this.currentVaNumber = this.bank.prefix + invoice.vatagihan.nomor;
                }
              },
              error: err => {
                this.uiService.showError("Terjadi kesalahan pembayaran formulir, Silahkan mengubungi admin");
                this.logout();
              }
            });

            // 2. generate VA number

            // const prefix = "80";
            // const curTimestamp = Math.floor(Date.now() / 1000);
            // const minm = 100;
            // const maxm = 999;
            // const threeDigits = Math.floor(Math.random() * (maxm - minm + 1)) + minm;

            // const vaNumber = prefix + curTimestamp.toString() + threeDigits.toString();

            // console.log('VA NUMBER', vaNumber, vaNumber.length);
            // const payload = {
            //   siswa_id: data.id,
            //   nomor_va: vaNumber
            // }
            // this.coreService.saveVaNumber(payload).subscribe({
            //   next: data => {
            //     console.log("DATA INVOICE", data);
            //     this.currentVaNumber = data.nomor_va;
            //     this.currentInvoice = data.invoice;
            //   },
            //   error: err => {
            //     this.logout();
            //   }
            // });

          } else if (data.current_status === "REGISTRATION_FEE") {
            for (let i = 0; i < data.SiswaInvoice.length; i++) {
              if (data.SiswaInvoice[i].akun === 'PSB') {
                this.currentVaNumber = data.SiswaInvoice[i].nomor_va;
                this.currentInvoice = data.SiswaInvoice[i].invoice;
              }
            }
          }

          this.uiService.hideLoader();
        }
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
        this.logout();
      }
    });
  }

  getSiswaDokumen(idsiswa: any) {
    this.uiService.showLoader();

    this.coreService.getCalonSiswaDokumen(idsiswa).subscribe({
      next: data => {
        console.log('DOKUMEN', data);
        if (data) {
          this.listSiswaDokumen = data;
          this.uiService.hideLoader();
        }
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  getOpenRegistration() {
    this.uiService.showLoader();

    this.coreService.getOpenRegistration().subscribe({
      next: data => {
        console.log(data);
        if (data) {
          this.openRegistration = data;
          this.uiService.hideLoader();
        } else {
          this.router.navigate(['regoff']);
        }
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  getRegistranData() {
    this.uiService.showLoader();

    this.coreService.getRegistrant(this.userData.iduser).subscribe({
      next: data => {
        console.log(data);
        this.openRegistration = data;
        this.uiService.hideLoader();
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  logout() {
    localStorage.clear();

    this.router.navigate(['home']);
  }

  confirmPayment() {
    this.uiService.showLoader();

    const tagihanPayload = {
      idsiswa: this.calonSiswa.id
    }

    this.coreService.getTagihanFormulir(tagihanPayload).subscribe({
      next: invoice => {
        this.uiService.hideLoader();
        console.log("DATA INVOICE1", this.currentBiaya, invoice);
        if (invoice === null) {
          this.uiService.showError("Belum ada tagihan biaya formulir");
        } else {          
          if (invoice.status_pembayaran === "LUNAS") {
            this.uiService.showSuccess("Pembayaran formulir sukses");
            this.changeRegStepToFillData();
          } else {
            this.uiService.showError("Tagihan belum dibayar, Silahkan melakukan pembayaran terlebih dahulu.");
          }
          this.currentVaNumber = this.bank.prefix + invoice.vatagihan.nomor;
        }
      },
      error: err => {
        this.uiService.hideLoader();
        this.uiService.showError("Error");
      }
    });

    // const payload = {
    //   "invoice": this.currentInvoice
    // }
    // this.coreService.changeInvoiceStatus(payload).subscribe({
    //   next: data => {
    //     console.log(data);
    //     this.uiService.hideLoader();

    //     this.changeRegStepToFillData();
    //   },
    //   error: err => {
    //     const errorMessage = err.error.message;

    //     this.uiService.hideLoader();

    //     this.uiService.showError(errorMessage);
    //   }
    // });
  }

  onSubmitDocument(): void {
    if (this.isEditDocumentValidation) {
      this.uiService.showSuccess("Update dokumen sukses");
      this.getDetailCalonSiswa();
    } else {
      this.changeRegStepToExamInformation();
    }
  }


  onSubmit(): void {
    this.submitted = true;

    if (this.formSiswa.invalid) {
      console.log(JSON.stringify(this.formSiswa.value, null, 2));
      this.uiService.showError('Data Form belum lengkap');
      return;
    }

    if (this.formSiswa.value.inputTahunLulus !== "" && this.formSiswa.value.inputBulanLulus !== "" && this.formSiswa.value.inputTglLulus !== "") {
      const tglLulus = this.formSiswa.value.inputTahunLulus + "-" + this.formSiswa.value.inputBulanLulus + "-" + this.formSiswa.value.inputTglLulus;
      console.log("submit tgl lulus", tglLulus);
        
      this.formSiswa.controls['tanggal_lulus'].setValue(tglLulus);
    }

    const dobAyah = this.formSiswa.value.inputTahunAyah + "-" + this.formSiswa.value.inputBulanAyah + "-" + this.formSiswa.value.inputTglAyah;  
    console.log("submit dob ayah", dobAyah);
    
    this.formSiswa.controls['dob_ayah'].setValue(dobAyah);

    const dobIbu = this.formSiswa.value.inputTahunIbu + "-" + this.formSiswa.value.inputBulanIbu + "-" + this.formSiswa.value.inputTglIbu;  
    this.formSiswa.controls['dob_ibu'].setValue(dobIbu);

    this.updateDataSiswa();

    console.log(JSON.stringify(this.formSiswa.value, null, 2));
  }

  updateDataSiswa() {
    this.uiService.showLoader();

    this.coreService.updateCalonSiswa(this.calonSiswa.id, this.formSiswa.value).subscribe({
      next: data => {
        console.log(data);
        this.uiService.hideLoader();
        if (this.editRegistrationData) {
          this.uiService.showSuccess("Update data pendaftaran sukses.");
          this.getDetailCalonSiswa();
        } else {
          this.changeRegStepToFillDocument();
        }
      },
      error: err => {
        const errorMessage = err.error.message;

        this.uiService.hideLoader();

        this.uiService.showError(errorMessage);
      }
    });
  }

  setFormatDob(ddob: any): any {
    return moment(ddob);
    // const ddate = moment(ddob);
    // console.log("MOMENT DATE ", ddate.date(), ddate.month(), ddate.year());
    
  }

  openPDF(): void {
    let DATA: any = document.getElementById('toPDF');
    //let DATA =  this.examCard != undefined ? this.examCard.nativeElement.innerHTML: "Error content";
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('kartu-ujian.pdf');
    });
  }

  print(): void {
    let DATA: any = document.getElementById('toPDF');
    //let DATA =  this.examCard != undefined ? this.examCard.nativeElement.innerHTML: "Error content";
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');

      let a = window.open('', '');
      a?.document.write('<html>');
      a?.document.write('<body onload="window.print();">');
      a?.document.write('<img src="'+FILEURI+'" />');
      //a?.document.write('<script>window.print();</script>')
      a?.document.write('</body></html>');
      a?.document.close();

      // a?.print();
      //a?.location.reload();

      // a?.document.onload = function () {
      //   alert("All done!");
      // }
    });
  }

  onNationalityChange(e: any) {
    const country = e.target.value;
    console.log(country);
    
    if (country === "WNI") {
      this.setValidatorWni()  
    } else {
      this.setValidatorWna();
    }

    this.formSiswa.controls['kewarganegaraan'].setValue(country);
  }

  onPenanggungBiayaChange(e: any) {
    const penanggungBiaya = e.target.value;
    
    if (penanggungBiaya === "AYAH") {
      const namaAyah = this.formSiswa.controls['nama_ayah'].value;
      const telpAyah = this.formSiswa.controls['telp_ayah'].value;
      const pekerjaanAyah = this.formSiswa.controls['pekerjaan_ayah'].value;
      const alamatAyah = this.formSiswa.controls['alamat_ayah'].value;
      
      this.formSiswa.controls['nama_penanggung_biaya'].setValue(namaAyah);
      this.formSiswa.controls['telepon_penanggung_biaya'].setValue(telpAyah);
      this.formSiswa.controls['pekerjaan_penanggung_biaya'].setValue(pekerjaanAyah);
      this.formSiswa.controls['alamat_penanggung_biaya'].setValue(alamatAyah);
    } else if (penanggungBiaya === "IBU") {
      const namaIbu = this.formSiswa.controls['nama_ibu'].value;
      const telpIbu = this.formSiswa.controls['telp_ibu'].value;
      const pekerjaanIbu = this.formSiswa.controls['pekerjaan_ibu'].value;
      const alamatIbu = this.formSiswa.controls['alamat_ibu'].value;
      
      this.formSiswa.controls['nama_penanggung_biaya'].setValue(namaIbu);
      this.formSiswa.controls['telepon_penanggung_biaya'].setValue(telpIbu);
      this.formSiswa.controls['pekerjaan_penanggung_biaya'].setValue(pekerjaanIbu);
      this.formSiswa.controls['alamat_penanggung_biaya'].setValue(alamatIbu);
    } else {
      this.formSiswa.controls['nama_penanggung_biaya'].setValue("");
      this.formSiswa.controls['telepon_penanggung_biaya'].setValue("");
      this.formSiswa.controls['pekerjaan_penanggung_biaya'].setValue("");
      this.formSiswa.controls['alamat_penanggung_biaya'].setValue("");
    }

  }

  setValidatorWni() {
    this.formSiswa.controls['alamat'].setValidators([Validators.required]);
    this.formSiswa.controls['alamat'].updateValueAndValidity();
    this.formSiswa.controls['rt'].setValidators([Validators.required]);
    this.formSiswa.controls['rt'].updateValueAndValidity();
    this.formSiswa.controls['rw'].setValidators([Validators.required]);
    this.formSiswa.controls['rw'].updateValueAndValidity();
    this.formSiswa.controls['provinsi'].setValidators([Validators.required]);
    this.formSiswa.controls['provinsi'].updateValueAndValidity();
    this.formSiswa.controls['kota_kab'].setValidators([Validators.required]);
    this.formSiswa.controls['kota_kab'].updateValueAndValidity();
    this.formSiswa.controls['kecamatan'].setValidators([Validators.required]);
    this.formSiswa.controls['kecamatan'].updateValueAndValidity();
    this.formSiswa.controls['desa_kelurahan'].setValidators([Validators.required]);
    this.formSiswa.controls['desa_kelurahan'].updateValueAndValidity();

    // clear negara
    this.formSiswa.controls['negara'].clearValidators();
    this.formSiswa.controls['negara'].updateValueAndValidity();
  }

  setValidatorWna() {
    this.formSiswa.controls['negara'].setValidators([Validators.required]);
    this.formSiswa.controls['negara'].updateValueAndValidity();

    this.formSiswa.controls['alamat'].clearValidators();
    this.formSiswa.controls['alamat'].updateValueAndValidity();
    this.formSiswa.controls['rt'].clearValidators();
    this.formSiswa.controls['rt'].updateValueAndValidity();
    this.formSiswa.controls['rw'].clearValidators();
    this.formSiswa.controls['rw'].updateValueAndValidity();
    this.formSiswa.controls['provinsi'].clearValidators();
    this.formSiswa.controls['provinsi'].updateValueAndValidity();
    this.formSiswa.controls['kota_kab'].clearValidators();
    this.formSiswa.controls['kota_kab'].updateValueAndValidity();
    this.formSiswa.controls['kecamatan'].clearValidators();
    this.formSiswa.controls['kecamatan'].updateValueAndValidity();
    this.formSiswa.controls['desa_kelurahan'].clearValidators();
    this.formSiswa.controls['desa_kelurahan'].updateValueAndValidity();
  }

  readURL(e: Event): void {
    const file = (e.target as HTMLInputElement).files;
    if (file?.length && file !== null) {
      const reader = new FileReader();
      reader.onload = (onLoad: any) => this.imgSrc = onLoad.target.result;

      reader.readAsDataURL(file[0])
    }

    // console.log(file?.item);

    // console.log((e.target as HTMLInputElement).files);
  }

  uploadPhoto(e: Event): void {
    const file = (e.target as HTMLInputElement).files;
    if (file?.length && file !== null) {
      this.uiService.showLoader();
      this.coreService.upload(file[0], 'siswa').subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            // progress
          } else if (event instanceof HttpResponse) {
            this.uiService.hideLoader();
            this.uiService.showSuccess(event.body.message);
            // this.currentSaveFileName = event.body.filename;
            console.log("FNAME", event.body.filename);
            console.log("INFO", event.body);
            
            this.imgSrc = this.coreService.getSiswaFileUrl(event.body.filename);
  
            this.savePhotoSiswa(event.body.filename);
            //this.fileInfos = this.uploadService.getFiles();
          }
        },
        error: (err: any) => {
          console.log(err);
          this.uiService.hideLoader();
  
          if (err.error && err.error.message) {
            this.uiService.showError(err.error.message);
          } else {
            this.uiService.showError('Could not upload the file!');
          }
        }
      });
    }
  }

}
