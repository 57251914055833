import { Component, OnInit } from '@angular/core';
import { UiService } from 'src/app/helper/ui.service';

declare var feather: any;

@Component({
  selector: 'app-main-app',
  templateUrl: './main-app.component.html',
  styleUrls: ['./main-app.component.css']
})
export class MainAppComponent implements OnInit {

  sidebarToggleClass: boolean = true;
  contentToggleClass: boolean = true;
  isFullScreen: boolean = false;

  constructor(private uiService: UiService) { 
    this.changeBodyClass();
  }

  ngOnInit(): void {
    feather.replace();

    this.uiService.showHideFeatherIcon();
    this.uiService.triggerOnScreenResize();
    this.uiService.displayWindowSize();

    //this.isFullScreen = this.uiService.isFullScreen();
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;    

    if (bodyElement) {
      bodyElement.classList.remove("login");
    }
  }  

  hideShowSidebarMenu() {
    this.sidebarToggleClass = !this.sidebarToggleClass;
    this.contentToggleClass = !this.contentToggleClass;
  }

  logout(): void {
    localStorage.clear();
    window.location.reload();
  }

  fullScreenDetect() {
    this.uiService.toggleFullscreen();
  }

}
