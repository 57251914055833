<div class="d-flex gap-5 align-items-center justify-content-center" style="height: 100vh">
  <div class="p-3 px-5 shadow d-flex flex-column justify-content-center align-items-center">
    <img
      src="./assets/images/logo-DN-large.png"
      alt=""
      style="max-width: 50%"
    />
    <div class="fw-bold fs-4 mt-3">DARUNNAJAH SYSTEM</div>
    <div class="text-center mt-4 mb-4 fs-6">
      Selamat Datang di Sistem Pendaftaran Online<br />Pondok Pesantren
      Darunnajah
    </div>

    <form
      [formGroup]="formDaftarSiswa"
      (ngSubmit)="onSubmit()"
      class="w-100"
      *ngIf="!isLoggedIn"
      autocomplete="off"
      novalidate
      (keydown)="keyDownFunction($event)"
    >
      <div class="row mt-4 align-items-center">
        <div class="mb-4">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend input-group-lg">
                  <div class="input-group-text" style="border-top-right-radius: 0; border-bottom-right-radius: 0;"><user-icon></user-icon></div>
              </div>
              <input
                type="text"
                formControlName="username"
                [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"
                class="form-control form-control-lg rounded-3"
                id="inlineFormUsername"
                placeholder="Enter Username"
                required
                style="border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;"
              />
            </div>
          </div>
          <div
            *ngIf="submitted && f['username'].errors"
            style="color: rgb(255, 126, 126)"
          >
            *Username tidak boleh kosong
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="mb-4">
          <div class="col-12">
            <div class="input-group">
                <div class="input-group-prepend input-group-lg">
                    <div class="input-group-text" style="border-top-right-radius: 0; border-bottom-right-radius: 0;"><lock-icon></lock-icon></div>
                </div>
                <input
                  id="inlineFormPassword"
                  type="password"
                  formControlName="password"
                  [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                  class="form-control form-control-lg rounded-3"
                  placeholder="Enter Password"
                  required
                  style="border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;"
                />
            </div>
          </div>
          <div
            *ngIf="submitted && f['password'].errors"
            style="color: rgb(255, 126, 126)"
          >
            *Password tidak boleh kosong
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex align-items-center justify-content-between gap-3 mb-3">
      <div
        (click)="onSubmit()"
        class="rounded-3 px-5 py-2 mt-3 gap-3 d-flex flex-1 text-white align-items-center py-3 justify-content-center"
        style="cursor: pointer; background: rgb(6, 149, 80); font-size: 1.1rem"
      >
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACESURBVHgB1ZNbCoAgEEWnNqpLaAfVSm0HtYPJCxkSjHdAfzogfihnXiriQFWT2uAseDxMBM5JOoEF+yyDmBuRIpb0grqf+ldyT0t5rYyUyajIK3tFjtHWREs0bGpNcqBQZbMZd2izqcQrSkziFWFiixCK6Ed/7RM1Ge+q9OYYkdGV134D9z47JdetmhIAAAAASUVORK5CYII="
          alt="user"
        />
        <div class="fw-semibold">Login</div>
      </div>

      <div
        (click)="gotoHomePage()"
        class="fw-bolder rounded-3 px-5 py-2 mt-3 gap-3 d-flex flex-1 align-items-center py-3 justify-content-center"
        style="cursor: pointer; background: rgb(242, 242, 242); color: rgb(6, 149, 80); font-size: 1.1rem;"
      >
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFYSURBVHgBtZXNbYNAEIVnMfYBLpRACbiEVJBw4O+U0AGpwKaCkApIDggBB9JBSjAlUAIXbvxkxsIIS9gSi/Nd4GHp7fq9YQG4QxiGSpIkL8CBcOuHOI5VWZZPjLEczY/wKOPNZpPjRaX7rutgKWzuYZZlH33fe4MsTNPcw0KEGdPDxLRs21YHDq52TEVRpoOs0HTvOE4JHIw7prLQNLxozNXnNR2NyRTL+sVbhTRG4du2HcAKzlGkaXrCizY8KzCCT+BEEITKsqwfRjOKERzggdA/FuAfoLkXd7td0DTNM65yiYJGzIcVYOlf54wn5amkMZqjYRirzMc5xgJpx9PJeMMSvoGTMWN8bWka3scVGQuiKNKAk6vyKBtqdJDKdrvNKSbg4NYhRAu8DrKo6/rJdd0KFjA7bqIoehhFMUhNkiQPFjJrrOt6hSNIp1pJmuc8vgvly/tp+gO4CJKC4Rnm4gAAAABJRU5ErkJggg=="
          alt="user"
        />
        <div>Kembali</div>
      </div>
    </div>

    <!-- <div class="d-flex flex-column my-3 mt-4 text-center">
            <img (click)="gotoHomePage()"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFYSURBVHgBtZXNbYNAEIVnMfYBLpRACbiEVJBw4O+U0AGpwKaCkApIDggBB9JBSjAlUAIXbvxkxsIIS9gSi/Nd4GHp7fq9YQG4QxiGSpIkL8CBcOuHOI5VWZZPjLEczY/wKOPNZpPjRaX7rutgKWzuYZZlH33fe4MsTNPcw0KEGdPDxLRs21YHDq52TEVRpoOs0HTvOE4JHIw7prLQNLxozNXnNR2NyRTL+sVbhTRG4du2HcAKzlGkaXrCizY8KzCCT+BEEITKsqwfRjOKERzggdA/FuAfoLkXd7td0DTNM65yiYJGzIcVYOlf54wn5amkMZqjYRirzMc5xgJpx9PJeMMSvoGTMWN8bWka3scVGQuiKNKAk6vyKBtqdJDKdrvNKSbg4NYhRAu8DrKo6/rJdd0KFjA7bqIoehhFMUhNkiQPFjJrrOt6hSNIp1pJmuc8vgvly/tp+gO4CJKC4Rnm4gAAAABJRU5ErkJggg=="
                    class="responsive-image" alt=""
                    style="cursor: pointer; left: 0%; top: 50%; transform: translateY(-50%);">

        </div> -->
  </div>
</div>
