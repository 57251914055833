import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-icon',
  templateUrl: './HomeIcon.component.html',
})

export class HomeIconComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
