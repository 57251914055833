<div class="sidebar-body">
    <ul>
        <a href="/">
            <li class="active">
                <img src="./assets/images/icon-home.png" alt="">
                Home
            </li>
        </a>
        <a data-bs-toggle="collapse" class="collapse-menu" href="#collapseExample" role="button"
            aria-expanded="false" aria-controls="collapseExample">
            <li>
                <div class="d-flex">
                    <img src="./assets/images/icon-student.png" alt="">
                    Data Siswa
                </div>
                <div class="icon-collapse">
                    <img src="./assets/images/chevron-down.svg" alt="">
                </div>
            </li>
        </a>
        <div class="collapse submenu" id="collapseExample">
            <ul>
                <a href="/siswa-baru.html">
                    <li>Siswa Baru</li>
                </a>
                <a href="">
                    <li>Hasil Ujian PPSB</li>
                </a>
                <a href="">
                    <li>Penempatan Kelas</li>
                </a>
                <a href="">
                    <li>Buku Induk</li>
                </a>
                <a href="">
                    <li>Tahun Ajaran Aktif</li>
                </a>
            </ul>
        </div>
        <a href="">
            <li>
                <img src="./assets/images/icon-school.png" alt="">
                Pendidikan
            </li>
        </a>
        <a href="">
            <li>
                <img src="./assets/images/icon-money.png" alt="">
                Keuangan
            </li>
        </a>
        <a href="">
            <li>
                <img src="./assets/images/icon-sdm.png" alt="">
                SDM
            </li>
        </a>
        <a href="">
            <li>
                <img src="./assets/images/icon-nurse.png" alt="">
                Pengasuhan
            </li>
        </a>
        <a href="">
            <li>
                <img src="./assets/images/icon-files.png" alt="">
                Master
            </li>
        </a>
        <a href="">
            <li>
                <img src="./assets/images/icon-tools.png" alt="">
                BRT
            </li>
        </a>
        <a href="">
            <li>
                <img src="./assets/images/icon-clipboard.png" alt="">
                Absen Kegiatan
            </li>
        </a>
        <a href="">
            <li>
                <img src="./assets/images/icon-rapor.png" alt="">
                Rapor
            </li>
        </a>
        <a href="">
            <li>
                <img src="./assets/images/icon-news.png" alt="">
                News
            </li>
        </a>
        <a href="">

            <li>
                <img src="./assets/images/icon-exams.png" alt="">
                Ujian Online
            </li>
        </a>
        <a href="">
            <li>
                <img src="./assets/images/icon-classroom.png" alt="">
                Google Classroom
            </li>
        </a>
        <a href="">
            <li>
                <img src="./assets/images/icon-task.png" alt="">
                Tugas Harian/Mingguan
            </li>
        </a>

        <a href="">
            <li>
                <img src="./assets/images/icon-task.png" alt="">
                Tugas Harian/Mingguan
            </li>
        </a>

        <a href="">
            <li>
                <img src="./assets/images/icon-task.png" alt="">
                Tugas Harian/Mingguan
            </li>
        </a>

        <a href="">
            <li>
                <img src="./assets/images/icon-task.png" alt="">
                Tugas Harian/Mingguan
            </li>
        </a>       

    </ul>
</div>