import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { NetworkService } from '../helper/network.service';
import { UiService } from '../helper/ui.service';
import { GeneralService } from '../service/general.service';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../helper/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  currentInstansi: any;

  formDaftarSiswa: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });
  submitted = false;

  constructor(private authService: AuthService, private commonService: CommonService, 
    private uiService: UiService, private formBuilder: FormBuilder,
    private router: Router, private networkService: NetworkService, private generalService: GeneralService) {
    this.changeBodyClass();
  }

  ngOnInit(): void {
    if (this.authService.isUserLoggedIn()) {
      this.router.navigate(['dashboard']);
    }
  }

  initForm() {
    this.formDaftarSiswa = this.formBuilder.group(
      {
        username: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40),
          ],
        ],
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formDaftarSiswa.controls;
  }

  getDetailInstansi() {
    this.uiService.showLoader();

    this.generalService.getCurrentInstansi().subscribe({
      next: data => {
        console.log(data);
        this.currentInstansi = data;

        this.uiService.hideLoader();

      },
      error: err => {
        this.errorMessage = err.error.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      bodyElement.classList.add("login");
    }
  }

  gotoHomePage() {
    this.router.navigate(['home']);
  }


  onSubmit(): void {
    this.submitted = true;

    if (this.formDaftarSiswa.invalid) {
      //this.uiService.showError('Data Form belum lengkap');
      return;
    }

    console.log(JSON.stringify(this.formDaftarSiswa.value, null, 2));

    this.doLogin();
  }

  doLogin(): void {
    this.uiService.showLoader();
    this.generalService.registrantLogin(this.formDaftarSiswa.value).subscribe({
      next: resdata => {
        console.log(resdata);

        this.uiService.hideLoader();
        this.uiService.showSuccess('Login sukses,');

        this.networkService.setCoreApiUrl(resdata.instansi);

        localStorage.setItem('token', resdata.token);
        localStorage.setItem('registrant', resdata.user);
        localStorage.setItem('instansi', resdata.instansi);
        this.isLoginFailed = false;
        this.isLoggedIn = true;

        const instansiType = this.commonService.getInstansiTypeByInstansiName(resdata.instansi);

        if (instansiType === "TK") {
          this.router.navigate(['dashboard-tk']);
        } else {
          this.router.navigate(['dashboard']);
        }
        //window.location.reload();

      },
      error: err => {
        console.log(err);

        this.errorMessage = err.message;
        console.log(this.errorMessage);

        this.uiService.hideLoader();

        this.uiService.showError(this.errorMessage);
      }
    });
  }

  keyDownFunction(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSubmit();
    }
  }

}
