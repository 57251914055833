<div *ngIf="currentInstansi != null" class="w-100 position-relative">
  <div *ngIf="calonSiswa != null" class="w-100 position-relative">
    <nav class="w-10 d-flex py-3 align-items-center justify-content-between" style="background: rgb(242, 242, 242)">
      <div class="d-flex responsive-web align-items-center justify-content-between" style="margin-inline: auto">
        <div class="d-flex align-items-center gap-4">
          <div class="rounded" style="height: 30px; width: 30px">
            <img width="30px" class="responsive-image" src="./assets/images/Logo-DN.png" alt=""/>
          </div>
          <div>{{ currentInstansi.nama_instansi }}</div>
        </div>
        <div>
          <span>{{ calonSiswa.nama_lengkap }}</span>&nbsp;&nbsp;&nbsp; |
          <span (click)="logout()" style="cursor: pointer">Keluar</span>
        </div>
      </div>
    </nav>

    <div class="responsive-web py-5 d-flex flex-column align-items-center" style="margin-inline: auto">
      <img src="./assets/images/bismillah.png" alt="" />
      <div class="text-center mt-4">
        Assalamu'alaikum, Wr,. Wb. Selamat Datang Calon Santri Baru di Pondok
        Pesantren Darunnajah.
        <br />
        <br />
        &nbsp;
      </div>

      <div *ngIf="showInfo" class="mt-4 rounded-3 p-3 px-4" style="background: rgba(153, 255, 202, 0.6)">
        <div class="fw-bold">Informasi</div>
        <div class="d-flex gap-3 mt-3 align-items-start">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGOSURBVHgBrVU7TsNAEJ3dNCmNEimtm0hOKtPRkZaKcAJChSjTcQuOQFLS+QY4J0iobMmN6UByYXp/eGOtIzC2Nwl+UrRjZ/bt25ndZ0ENsCxrJoS4Rsijmee5gTHGGOKdmyTJOgiCXd1cUUNmYvIzk5EeDhZZ+r4f/nzZqxDeSikdDukwWBCwGA6HH1EUvf0hZUIkrBD26Tj0MW8O4rAkForQhMIt141OB9f7nEshC2YhnjSE3JAbNTbBUL0gOR6PbYxzasfa8zyu9VqTN+NT0xuNRo94uNAkW6jZGZTcs6K2ROR8iclkskVsU0cA6U7ywT4g18X2Beru6hKRY8p/drwOBiuNqVvEUt3lzgCRxTndUIeAyA0rdahDwL1WEtfK1XUVWzKUFeqa6rAdSvVw19YwLGrj/1dqOc/Ka5ccFy4Fd4kHg8Enuw2dCBA+8K73pIp4h6v4Drea0RH2pxQy4ap898ukmRiKX/hWIFlr1NwL/K5KhfuFmiZMp1M7TdMFlF9Wv1FZlm0QO1WyEt9D77ljlqcvXQAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="text-justify" [innerHtml]="contentRegis | safeHtml"></div>
        </div>
      </div>

      <div class="my-4 fw-bolder fs-5">Tahapan Pendaftaran Online</div>
      <div class="text-center">
        Berikut ini Tahapan Pendaftaran Online Calon Santri Baru di Pondok
        Pesantren Darunnajah. <br />Mohon untuk melakukan tahapan pendaftaran
        sampai akhir.
      </div>
      <div class="w-100 d-flex flex-column gap-3">
        <div class="rounded-3 d-flex align-items-center gap-4 py-3 px-4 w-100 mt-4" style="background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFKSURBVHgB7VUxUsMwEDwpFJThB7IrWn4g+wOQH5gOKkhHF7mkyuQH8ALCB2zzAviB9QS9wGaVUWYyGVtRxnaXbXTWnXd11t2ZEZCm6apt21eYcxoHhjH2URTFkoF87chHB0RyDvKMJgK4XziN91m6MOc0MS4Cu0rBZd7B1F3+KxoAkG9Q68raKPc/CInjmCEZ6KZplDWklBnIH7qCfAIaL209vqSqKgNygUzWfSS9AiBYgGCBNe/wLeHTjrwkTy/1CnDO7+0KInUoYi8Ve1sXY08uyINZFEWqxyfjOKa6rn+01pUQgmHPlGX5vHNKucLyRCfgvWScXNlJu88Ey6Mjz5CJogCwJEnak0EgQznmjtx+918KnGFBfeAyIZTlN8i/6IwBGZTBEFyGXZCAoelg7D95QxMBFfc5c116g4db7F3TODA4+Du6/u0fDLmOQlqxGa4AAAAASUVORK5CYII="
            alt=""
          />
          <div class="fw-semibold" style="color: rgb(170, 170, 170)">
            1. Regristasi
          </div>
        </div>

        <div *ngIf="showDoneStep2" class="rounded-3 d-flex align-items-center gap-4 py-3 px-4 w-100" style="background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFKSURBVHgB7VUxUsMwEDwpFJThB7IrWn4g+wOQH5gOKkhHF7mkyuQH8ALCB2zzAviB9QS9wGaVUWYyGVtRxnaXbXTWnXd11t2ZEZCm6apt21eYcxoHhjH2URTFkoF87chHB0RyDvKMJgK4XziN91m6MOc0MS4Cu0rBZd7B1F3+KxoAkG9Q68raKPc/CInjmCEZ6KZplDWklBnIH7qCfAIaL209vqSqKgNygUzWfSS9AiBYgGCBNe/wLeHTjrwkTy/1CnDO7+0KInUoYi8Ve1sXY08uyINZFEWqxyfjOKa6rn+01pUQgmHPlGX5vHNKucLyRCfgvWScXNlJu88Ey6Mjz5CJogCwJEnak0EgQznmjtx+918KnGFBfeAyIZTlN8i/6IwBGZTBEFyGXZCAoelg7D95QxMBFfc5c116g4db7F3TODA4+Du6/u0fDLmOQlqxGa4AAAAASUVORK5CYII="
            alt=""
          />
          <div class="fw-semibold" style="color: rgb(170, 170, 170)">
            2. Bayar Biaya Pendaftaran
          </div>
        </div>

        <div *ngIf="showDoneStep3" class="rounded-3 d-flex align-items-center gap-4 py-3 px-4 w-100" style="background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFKSURBVHgB7VUxUsMwEDwpFJThB7IrWn4g+wOQH5gOKkhHF7mkyuQH8ALCB2zzAviB9QS9wGaVUWYyGVtRxnaXbXTWnXd11t2ZEZCm6apt21eYcxoHhjH2URTFkoF87chHB0RyDvKMJgK4XziN91m6MOc0MS4Cu0rBZd7B1F3+KxoAkG9Q68raKPc/CInjmCEZ6KZplDWklBnIH7qCfAIaL209vqSqKgNygUzWfSS9AiBYgGCBNe/wLeHTjrwkTy/1CnDO7+0KInUoYi8Ve1sXY08uyINZFEWqxyfjOKa6rn+01pUQgmHPlGX5vHNKucLyRCfgvWScXNlJu88Ey6Mjz5CJogCwJEnak0EgQznmjtx+918KnGFBfeAyIZTlN8i/6IwBGZTBEFyGXZCAoelg7D95QxMBFfc5c116g4db7F3TODA4+Du6/u0fDLmOQlqxGa4AAAAASUVORK5CYII="
            alt=""
          />
          <div class="fw-semibold" style="color: rgb(170, 170, 170)">
            3. Isi Data Pendaftaran
          </div>
          <div>
            <button
              (click)="editFillRegistration()"
              class="px-4 rounded-3 py-2 text-white fw-bold"
              style="border: none; background: rgb(6, 149, 80)"
            >
              Edit
            </button>
          </div>
        </div>

        <div *ngIf="showDoneStep4" class="rounded-3 d-flex align-items-center gap-4 py-3 px-4 w-100" style="background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFKSURBVHgB7VUxUsMwEDwpFJThB7IrWn4g+wOQH5gOKkhHF7mkyuQH8ALCB2zzAviB9QS9wGaVUWYyGVtRxnaXbXTWnXd11t2ZEZCm6apt21eYcxoHhjH2URTFkoF87chHB0RyDvKMJgK4XziN91m6MOc0MS4Cu0rBZd7B1F3+KxoAkG9Q68raKPc/CInjmCEZ6KZplDWklBnIH7qCfAIaL209vqSqKgNygUzWfSS9AiBYgGCBNe/wLeHTjrwkTy/1CnDO7+0KInUoYi8Ve1sXY08uyINZFEWqxyfjOKa6rn+01pUQgmHPlGX5vHNKucLyRCfgvWScXNlJu88Ey6Mjz5CJogCwJEnak0EgQznmjtx+918KnGFBfeAyIZTlN8i/6IwBGZTBEFyGXZCAoelg7D95QxMBFfc5c116g4db7F3TODA4+Du6/u0fDLmOQlqxGa4AAAAASUVORK5CYII="
            alt=""
          />
          <div class="fw-semibold" style="color: rgb(170, 170, 170)">
            4. Validasi Dokumen
          </div>
          <div>
            <button
              (click)="editDocumentValidation()"
              class="px-4 rounded-3 py-2 text-white fw-bold"
              style="border: none; background: rgb(6, 149, 80)"
            >
              Edit
            </button>
          </div>
        </div>

        <div *ngIf="showDoneStep5" class="rounded-3 d-flex align-items-center gap-4 py-3 px-4 w-100" style="background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFKSURBVHgB7VUxUsMwEDwpFJThB7IrWn4g+wOQH5gOKkhHF7mkyuQH8ALCB2zzAviB9QS9wGaVUWYyGVtRxnaXbXTWnXd11t2ZEZCm6apt21eYcxoHhjH2URTFkoF87chHB0RyDvKMJgK4XziN91m6MOc0MS4Cu0rBZd7B1F3+KxoAkG9Q68raKPc/CInjmCEZ6KZplDWklBnIH7qCfAIaL209vqSqKgNygUzWfSS9AiBYgGCBNe/wLeHTjrwkTy/1CnDO7+0KInUoYi8Ve1sXY08uyINZFEWqxyfjOKa6rn+01pUQgmHPlGX5vHNKucLyRCfgvWScXNlJu88Ey6Mjz5CJogCwJEnak0EgQznmjtx+918KnGFBfeAyIZTlN8i/6IwBGZTBEFyGXZCAoelg7D95QxMBFfc5c116g4db7F3TODA4+Du6/u0fDLmOQlqxGa4AAAAASUVORK5CYII="
            alt=""
          />
          <div class="fw-semibold" style="color: rgb(170, 170, 170)">
            5. Ujian Masuk
          </div>
        </div>

        <div *ngIf="showDoneStep6" class="rounded-3 d-flex align-items-center gap-4 py-3 px-4 w-100" style="background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFKSURBVHgB7VUxUsMwEDwpFJThB7IrWn4g+wOQH5gOKkhHF7mkyuQH8ALCB2zzAviB9QS9wGaVUWYyGVtRxnaXbXTWnXd11t2ZEZCm6apt21eYcxoHhjH2URTFkoF87chHB0RyDvKMJgK4XziN91m6MOc0MS4Cu0rBZd7B1F3+KxoAkG9Q68raKPc/CInjmCEZ6KZplDWklBnIH7qCfAIaL209vqSqKgNygUzWfSS9AiBYgGCBNe/wLeHTjrwkTy/1CnDO7+0KInUoYi8Ve1sXY08uyINZFEWqxyfjOKa6rn+01pUQgmHPlGX5vHNKucLyRCfgvWScXNlJu88Ey6Mjz5CJogCwJEnak0EgQznmjtx+918KnGFBfeAyIZTlN8i/6IwBGZTBEFyGXZCAoelg7D95QxMBFfc5c116g4db7F3TODA4+Du6/u0fDLmOQlqxGa4AAAAASUVORK5CYII="
            alt=""
          />
          <div class="fw-semibold" style="color: rgb(170, 170, 170)">
            6. Surat Keputusan Dan Biaya Santri Baru 2023/2024
          </div>
        </div>

        <div *ngIf="showStep2" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: 1px solid black">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="fw-semibold">2. Bayar Biaya Pendaftaran</div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="showStep2" class="w-100 p-5" style="background: rgb(242, 242, 242)">
          <div class="fw-bold fs-4" style="color: rgb(38, 82, 152)">
            Yang Terhormat {{ calonSiswa.nama_lengkap }}
          </div>
          <div class="mt-3">
            Terima Kasih Telah Bergabung di <b>{{ currentInstansi.nama_instansi }}</b >. <br />
            Pendaftaran akan segera kami proses setelah Anda melakukan
            pembayaran di ATM atau fasilitas Internet Banking yang terhubung
            dengan jaringan ATM Bersama, Prima, atau ALTO. Pembayaran Melalui:
          </div>

          <div class="d-flex flex-column flex-md-row gap-5 mt-4">
            <div class="p-4" style="background: white">
              <div class="fw-bold fs-6">Informasi Santri</div>
              <div class="d-flex flex-column gap-2 mt-4">
                <div class="w-100 justify-content-between d-flex gap-5">
                  <div>Nama (Calon Santri)</div>
                  <div>{{ calonSiswa.nama_lengkap }}</div>
                </div>

                <div class="w-100 justify-content-between d-flex gap-5">
                  <div>Pilihan Lembaga</div>
                  <div>
                    <div>{{ currentLembaga }}</div>
                    <div>{{ currentTingkat }}</div>
                  </div>
                </div>
                <hr />
                <div class="w-100 justify-content-between d-flex gap-5">
                  <div>Email</div>
                  <div>{{ userData.Registrant.email }}</div>
                </div>
                <div class="w-100 justify-content-between d-flex gap-5">
                  <div>No. Telepon</div>
                  <div>{{ userData.Registrant.telepon }}</div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column gap-4">
              <div class="p-4 rounded-3" style="background: white">
                <div>
                  <div class="fw-bold mb-4 fs-6">Pembayaran Via:</div>
                  <div class="d-flex gap-5">
                    <div style="width: 400px">
                      <img
                        src="./assets/images/Bank_Syariah_Indonesia.svg"
                        alt=""
                        style="max-width: 100%; max-height: 100%; height: auto"
                      />
                      <div>Bank Syariah Indonesia</div>
                      <hr />
                      <div>Nomor Virtual Account:</div>
                      <div class="fw-bold">{{ currentVaNumber }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-4 rounded-3" style="background: white; border-top: 1px solid rgb(6, 149, 80)">
                <div class="fw-bold fs-6 mb-4">Detail Pembayaran</div>
                <div class="d-flex w-100 justify-content-between">
                  <div>Total Pendaftaran</div>
                  <div>{{ currentBiaya | currency : "Rp" }}</div>
                  <!-- <div>Rp 300.000,00,-</div> -->
                </div>
                <!-- <div class="d-flex w-100 justify-content-between">
                  <div>Biaya Lain-Lain</div>
                  <div>Rp 10.000,00,-</div>
                </div> -->
                <hr />
                <div class="d-flex w-100 mt-4 justify-content-between">
                  <div>
                    <!-- <div>Total</div>
                    <div>Rp 310.000,00,-</div> -->
                  </div>
                  <button (click)="confirmPayment()" class="px-4 rounded-3 py-2 text-white fw-bold" style="border: none; background: rgb(6, 149, 80)">
                    Konfirmasi Pembayaran
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <hr />
            <br />
          </div>
          <div class="mt-3" [innerHtml]="contentRegis | safeHtml"></div>
        </div>

        <!-- ISI DATA SANTRI LENGKAP-->
        <div id="fill-registration-data" *ngIf="showStep3" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: 1px solid black">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="fw-semibold">3. Isi Data Pendaftaran</div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div [ngClass]="{ 'd-none': !showStep3 }" class="w-100 d-flex flex-column gap-3" style="background: rgb(242, 242, 242); padding-block: 2rem">
          <form [formGroup]="formSiswa" autocomplete="off" (ngSubmit)="onSubmit()">
            <div style="margin-left: auto; margin-right: auto">
              <div class="fw-bold px-3">Calon Santri</div>
              <div class="d-flex flex-column gap-3 mt-3 px-3">

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nomor Induk Sekolah Nasional (NISN)<span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      type="text"
                      class="form-control rounded-3 py-2"
                      formControlName="nisn"
                      [ngClass]="{'is-invalid': submitted && f['nisn'].errors}"
                      placeholder="NISN"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div class="mt-2" style="color: rgb(255, 126, 126)" *ngIf="submitted && f['nisn'].errors">
                      Nomor Induk Sekolah Nasional (NISN) is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nomor Pokok Sekolah Nasional (NPSN)<span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="npsn"
                      [ngClass]="{'is-invalid': submitted && f['npsn'].errors}"
                      placeholder="NPSN"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['npsn'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      NPSN is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nomor Statistik Sekolah(NSS) / Nomor Statistik Madrasah
                    <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="nss"
                      [ngClass]="{ 'is-invalid': submitted && f['nss'].errors }"
                      placeholder="NSS/NSM"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['npsn'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      NSS is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">Nomor Ijazah</div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="no_ijazah"
                      [ngClass]="{'is-invalid': submitted && f['no_ijazah'].errors}"
                      placeholder="Nomor Ijazah"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['npsn'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Nomor Ijazah is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nama Lengkap <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="nama_lengkap"
                      [ngClass]="{'is-invalid': submitted && f['nama_lengkap'].errors}"
                      placeholder="Nama Lengkap"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['nama_lengkap'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Nama Lengkap is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nomor Induk Kependudukan (NIK)<span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="nik"
                      [ngClass]="{ 'is-invalid': submitted && f['nik'].errors }"
                      placeholder="NIK"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['nik'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      NIK is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Jenis Kelamin <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="gender"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['gender'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="L">Laki-laki</option>
                      <option value="P">Perempuan</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Tempat Lahir <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="pob"
                      [ngClass]="{ 'is-invalid': submitted && f['pob'].errors }"
                      placeholder="Tempat Lahir"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['nik'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Tempat Lahir is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Tanggal Lahir <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      type="text"
                      autocomplete="off"
                      id="birtdhdate"
                      (blur)="changeDobField($event)"
                      formControlName="dob"
                      class="form-control rounded-3 py-2"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                      [ngClass]="{ 'is-invalid': submitted && f['dob'].errors }"
                      placeholder="Tanggal Lahir"
                    />
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Urutan Dalam Keluarga <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative d-flex align-items-center gap-3" style="flex: 1.5">
                    <div>Anak ke -</div>
                    <input
                      class="form-control rounded-3 py-2"
                      type="number"
                      formControlName="anak_ke"
                      [ngClass]="{'is-invalid': submitted && f['anak_ke'].errors}"
                      placeholder="0"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />

                    <div>Dari</div>
                    <input
                      class="form-control rounded-3 py-2"
                      type="number"
                      formControlName="jumlah_anak"
                      [ngClass]="{'is-invalid': submitted && f['jumlah_anak'].errors}"
                      placeholder="0"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nomor Telepon <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="telepon"
                      [ngClass]="{'is-invalid': submitted && f['telepon'].errors}"
                      placeholder="Nomor Telepon"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['telepon'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      telepon is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Penyakit yang pernah diderita
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="riwayat_penyakit"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Berat Badan <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="berat_badan"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Tinggi Badan <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="tinggi_badan"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Golongan Darah <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="golongan_darah"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['golongan_darah'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="AB">AB</option>
                      <option value="O">O</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">Hoby</div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="hoby"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Ukuran Baju <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="ukuran_baju"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['golongan_darah'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Email <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="email"
                      [ngClass]="{'is-invalid': submitted && f['email'].errors}"
                      placeholder="mail@gmail.com"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['email'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Email is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Saudara Kandung Yang Masih Aktif Menjadi Santri
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="saudara_kandung_aktif"
                      placeholder="Nama, Kelas, ..."
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Informasi Sekolah Didapatkan Dari
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="sumber_info_sekolah"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                  </div>
                </div>
                <hr />
              </div>

              <div class="fw-bold px-3">Jenjang</div>
              <div class="d-flex flex-column gap-3 mt-3 px-3">

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Lembaga yang dituju <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="rounded-3 py-2"
                      [disabled]="true"
                      [value]="currentLembaga"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Kelas yang dituju <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="rounded-3 py-2"
                      [disabled]="true"
                      [value]="currentTingkat"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Sekolah Asal <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="asal_sekolah"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['asal_sekolah'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="MI">MI</option>
                      <option value="SD">SD</option>
                      <option value="MTS">MTs</option>
                      <option value="SMP">SMP</option>
                      <option value="MA/SMA">MA/SMA</option>
                    </select>
                    <div *ngIf="submitted && f['asal_sekolah'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Sekolah Asal is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nama Sekolah Asal <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="nama_sekolah_asal"
                      [ngClass]="{'is-invalid': submitted && f['nama_sekolah_asal'].errors}"
                      placeholder="Nama Sekolah Asal"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['nama_sekolah_asal'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Nama Sekolah Asal is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">Tanggal Lulus</div>
                  <div class="position-relative" style="flex: 1.5">
                    <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-2 justify-content-between">
                      <select
                        formControlName="inputTglLulus"
                        class="form-select rounded-3 py-2"
                        [ngClass]="{'is-invalid': submitted && f['inputTglLulus'].errors}"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      >
                        <option value="" selected disabled>Pilih Tgl</option>
                        <option *ngFor="let tgl of tanggalList" [value]="tgl">{{ tgl }}</option>
                      </select>
                      <select
                        formControlName="inputBulanLulus"
                        class="form-select rounded-3 py-2"
                        [ngClass]="{'is-invalid': submitted && f['inputBulanLulus'].errors}"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      >
                        <option value="" selected disabled>Pilih Bulan</option>
                        <option *ngFor="let bln of bulanList" [value]="bln.month">{{ bln.name }}</option>
                      </select>
                      <select
                        formControlName="inputTahunLulus"
                        class="form-select rounded-3 py-2"
                        [ngClass]="{'is-invalid': submitted && f['inputTahunLulus'].errors}"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      >
                        <option value="" selected disabled>Pilih Tahun</option>
                        <option *ngFor="let th of tahunList" [value]="th">{{ th }}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <hr />
              </div>

              <div class="fw-bold px-3">Orang Tua</div>
              <div class="d-flex flex-column gap-3 mt-3 px-3">

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nomor Kartu Keluarga <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="no_kk"
                      [ngClass]="{'is-invalid': submitted && f['no_kk'].errors}"
                      placeholder="Nomor Kartu Keluarga"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['no_kk'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Nomor Kartu Keluarga
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nama Ayah <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="nama_ayah"
                      [ngClass]="{'is-invalid': submitted && f['nama_ayah'].errors}"
                      placeholder="Nama Ayah"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['nama_ayah'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Nama Ayah is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    NIK Ayah <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="nik_ayah"
                      [ngClass]="{'is-invalid': submitted && f['nik_ayah'].errors}"
                      placeholder="NIK Ayah"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['nik_ayah'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      NIK Ayah is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Status Ayah <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="status_ayah"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['status_ayah'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="KANDUNG">Kandung</option>
                      <option value="TIRI">Tiri</option>
                      <option value="ANGKAT">Angkat</option>
                    </select>
                    <div *ngIf="submitted && f['status_ayah'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Status Ayah is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Tempat Lahir Ayah <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="pob_ayah"
                      [ngClass]="{'is-invalid': submitted && f['pob_ayah'].errors}"
                      placeholder="Tempat Lahir Ayah"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['pob_ayah'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Tempat Lahir Ayah is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Tanggal Lahir Ayah<span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-2 justify-content-between">
                      <select
                        formControlName="inputTglAyah"
                        class="form-select rounded-3 py-2"
                        [ngClass]="{'is-invalid': submitted && f['inputTglAyah'].errors}"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      >
                        <option value="" selected disabled>Pilih Tgl</option>
                        <option *ngFor="let tgl of tanggalList" [value]="tgl">{{ tgl }}</option>
                      </select>
                      <select formControlName="inputBulanAyah" class="form-select rounded-3 py-2" [ngClass]="{'is-invalid': submitted && f['inputBulanAyah'].errors}" style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;">
                        <option value="" selected disabled>Pilih Bulan</option>
                        <option *ngFor="let bln of bulanList" [value]="bln.month">{{ bln.name }}</option>
                      </select>
                      <select
                        formControlName="inputTahunAyah"
                        class="form-select rounded-3 py-2"
                        [ngClass]="{'is-invalid': submitted && f['inputTahunAyah'].errors}"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      >
                        <option value="" selected disabled>Pilih Tahun</option>
                        <option *ngFor="let th of tahunList" [value]="th">{{ th }}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Gelar Akademik <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="gelar_akademik_ayah"
                      [ngClass]="{'is-invalid': submitted && f['gelar_akademik_ayah'].errors}"
                      placeholder="Gelar Akademik"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['gelar_akademik_ayah'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Gelar Akademik is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Status <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="status_hidup_ayah"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['status_hidup_ayah'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="HIDUP">Masih Hidup</option>
                      <option value="MENINGGAL">Sudah Meninggal Dunia</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Pendidikan Terakhir <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="pendidikan_terkahir_ayah"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['pendidikan_terkahir_ayah'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="TIDAK_TAMAT">Tidak Tamat SD/MI/Sederajat</option>
                      <option value="SD">SD/MI/Sederajat</option>
                      <option value="SMP">SMP/MTS/Sederajat</option>
                      <option value="SMA">SMA/MA/Sederajat</option>
                      <option value="S1">Sarjana (S1)</option>
                      <option value="S2">Magister (S2)</option>
                      <option value="S3">Doktoral (S3)</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1;">Pekerjaan Ayah <span class="text-danger">*</span></div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="pekerjaan_ayah"
                      [ngClass]="{'is-invalid': submitted && f['pekerjaan_ayah'].errors}"
                      placeholder="Pekerjaan Ayah"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['pekerjaan_ayah'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Pekerjaan Ayah is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 justify-content-between align-items-center gap-3">
                  <div style="white-space: nowrap; flex: 1;">
                    Penghasilan Perbulan <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="penghasilan_perbulan_ayah"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['penghasilan_perbulan_ayah'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="1">&#60; Rp 500.000,00</option>
                      <option value="2">Rp 500.001,00 - Rp 1.000.000,00</option>
                      <option value="3">Rp 1.000.001,00 - Rp 2.000.000,00</option>
                      <option value="4">Rp 2.000.001,00 - Rp 3.000.000,00</option>
                      <option value="5">Rp 3.000.001,00 - Rp 4.000.000,00</option>
                      <option value="6">Rp 4.000.001,00 - Rp 5.000.000,00</option>
                      <option value="7">&#62; Rp 5.000.000,00</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Telepon <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="telp_ayah"
                      [ngClass]="{'is-invalid': submitted && f['telp_ayah'].errors}"
                      placeholder="081237xxx"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['telp_ayah'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Telepon is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1;">Alamat Ayah <span class="text-danger">*</span></div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="alamat_ayah"
                      [ngClass]="{'is-invalid': submitted && f['alamat_ayah'].errors}"
                      placeholder="Alamat Ayah"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['alamat_ayah'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Alamat Ayah is required
                    </div>
                  </div>
                </div>

                <hr />

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nama Ibu <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="nama_ibu"
                      [ngClass]="{'is-invalid': submitted && f['nama_ibu'].errors}"
                      placeholder="Nama Ibu"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['nama_ibu'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Nama Ibu is required
                    </div>
                  </div>
                </div>
                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    NIK Ibu <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="nik_ibu"
                      [ngClass]="{'is-invalid': submitted && f['nik_ibu'].errors}"
                      placeholder="NIK Ibu"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['nik_ibu'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      NIK Ibu is required
                    </div>
                  </div>
                </div>
                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Status Ibu <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="status_ibu"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['status_ibu'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="KANDUNG">Kandung</option>
                      <option value="TIRI">Tiri</option>
                      <option value="ANGKAT">Angkat</option>
                    </select>
                    <div *ngIf="submitted && f['status_ibu'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Status Ibu is required
                    </div>
                  </div>
                </div>
                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Tempat Lahir Ibu <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="pob_ibu"
                      [ngClass]="{'is-invalid': submitted && f['pob_ibu'].errors}"
                      placeholder="Tempat Lahir Ibu"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['pob_ibu'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Tempat Lahir Ibu is required
                    </div>
                  </div>
                </div>
                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Tanggal Lahir Ibu <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-2 justify-content-between">
                      <select
                        formControlName="inputTglIbu"
                        class="form-select rounded-3 py-2"
                        [ngClass]="{'is-invalid': submitted && f['inputTglIbu'].errors}"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      >
                        <option value="" selected disabled>Pilih Tgl</option>
                        <option *ngFor="let tgl of tanggalList" [value]="tgl">{{ tgl }}</option>
                      </select>
                      <select
                        formControlName="inputBulanIbu"
                        class="form-select rounded-3 py-2"
                        [ngClass]="{'is-invalid': submitted && f['inputBulanIbu'].errors}"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      >
                        <option value="" selected disabled>Pilih Bulan</option>
                        <option *ngFor="let bln of bulanList" [value]="bln.month">{{ bln.name }}</option>
                      </select>
                      <select
                        formControlName="inputTahunIbu"
                        class="form-select rounded-3 py-2"
                        [ngClass]="{'is-invalid': submitted && f['inputTahunIbu'].errors}"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      >
                        <option value="" selected disabled>Pilih Tahun</option>
                        <option *ngFor="let th of tahunList" [value]="th">{{ th }}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Gelar Akademik <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="gelar_akademik_ibu"
                      [ngClass]="{'is-invalid': submitted && f['gelar_akademik_ibu'].errors}"
                      placeholder="Gelar Akademik"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['gelar_akademik_ibu'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Gelar Akademik is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Status <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="status_hidup_ibu"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['status_hidup_ibu'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="HIDUP">Masih Hidup</option>
                      <option value="MENINGGAL">Sudah Meninggal Dunia</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Pendidikan Terakhir <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="pendidikan_terkahir_ibu"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['pendidikan_terkahir_ibu'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="TIDAK_TAMAT">Tidak Tamat SD/MI/Sederajat</option>
                      <option value="SD">SD/MI/Sederajat</option>
                      <option value="SMP">SMP/MTS/Sederajat</option>
                      <option value="SMA">SMA/MA/Sederajat</option>
                      <option value="S1">Sarjana (S1)</option>
                      <option value="S2">Magister (S2)</option>
                      <option value="S3">Doktoral (S3)</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1;">Pekerjaan Ibu <span class="text-danger">*</span></div>
                  <div class="position-relative" style="flex: 1.5;">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="pekerjaan_ibu"
                      [ngClass]="{'is-invalid': submitted && f['pekerjaan_ibu'].errors}"
                      placeholder="Pekerjaan Ibu"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['pekerjaan_ibu'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Pekerjaan Ibu is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 justify-content-between align-items-center gap-3">
                  <div style="white-space: nowrap; flex: 1;">Penghasilan Perbulan <span class="text-danger">*</span></div>
                  <div class="position-relative" style="flex: 1.5;">
                    <select
                      formControlName="penghasilan_perbulan_ibu"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['penghasilan_perbulan_ibu'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="1">&#60; Rp 500.000,00</option>
                      <option value="2">Rp 500.001,00 - Rp 1.000.000,00</option>
                      <option value="3">Rp 1.000.001,00 - Rp 2.000.000,00</option>
                      <option value="4">Rp 2.000.001,00 - Rp 3.000.000,00</option>
                      <option value="5">Rp 3.000.001,00 - Rp 4.000.000,00</option>
                      <option value="6">Rp 4.000.001,00 - Rp 5.000.000,00</option>
                      <option value="7">&#62; Rp 5.000.000,00</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Telepon <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="telp_ibu"
                      [ngClass]="{'is-invalid': submitted && f['telp_ibu'].errors}"
                      placeholder="081237xxx"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['telp_ibu'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Telepon is required
                    </div>
                  </div>
                </div>
                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1;">Alamat Ibu <span class="text-danger">*</span></div>
                  <div class="position-relative" style="flex: 1.5;">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="alamat_ibu"
                      [ngClass]="{'is-invalid': submitted && f['alamat_ibu'].errors}"
                      placeholder="Alamat Ibu"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['alamat_ibu'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Alamat Ibu is required
                    </div>
                  </div>
                </div>

                <hr />
              </div>

              <div class="d-flex flex-column gap-3 mt-3 px-3">
                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Yang Menanggung Biaya
                  </div>
                  <div class="position-relative" style="flex: 1.5">                    
                    <select
                      formControlName="yang_menanggung_biaya"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['yang_menanggung_biaya'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      (change)="onPenanggungBiayaChange($event)"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="AYAH">AYAH</option>
                      <option value="IBU">IBU</option>
                      <option value="LAINNYA">LAINNYA</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Nama <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="nama_penanggung_biaya"
                      [ngClass]="{'is-invalid': submitted && f['nama_penanggung_biaya'].errors}"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['nama_penanggung_biaya'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Nama is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Hubungan Keluarga <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="hubungan_keluarga_penanggung_biaya"
                      [ngClass]="{'is-invalid': submitted && f['hubungan_keluarga_penanggung_biaya'].errors}"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['hubungan_keluarga_penanggung_biaya'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Hubungan Keluarga is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Telepon <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="telepon_penanggung_biaya"
                      [ngClass]="{'is-invalid': submitted && f['telepon_penanggung_biaya'].errors}"
                      placeholder="081237xxx"
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['telepon_penanggung_biaya'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Telepon is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Pekerjaan <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="pekerjaan_penanggung_biaya"
                      [ngClass]="{'is-invalid': submitted && f['pekerjaan_penanggung_biaya'].errors}"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['pekerjaan_penanggung_biaya'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Pekerjaan is required
                    </div>
                  </div>
                </div>

                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">
                    Alamat Lengkap <span class="text-danger">*</span>
                  </div>
                  <div class="position-relative" style="flex: 1.5">
                    <input
                      class="form-control rounded-3 py-2"
                      formControlName="alamat_penanggung_biaya"
                      [ngClass]="{'is-invalid': submitted && f['alamat_penanggung_biaya'].errors}"
                      placeholder=""
                      style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                    />
                    <div *ngIf="submitted && f['alamat_penanggung_biaya'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                      Alamat Lengkap is required
                    </div>
                  </div>
                </div>

                <hr />
              </div>

              <div class="fw-bold px-3">Alamat Sesuai Domisili</div>
              <div class="d-flex flex-column gap-3 mt-3 px-3">
                <div class="d-flex w-100 justify-content-between align-items-center gap-3">
                  <div style="white-space: nowrap; flex: 1;">Kewarganegaraan <span class="text-danger">*</span></div>
                  <div class="position-relative" style="flex: 1.5;">
                    <select
                      formControlName="kewarganegaraan"
                      class="form-select rounded-3 py-2"
                      [ngClass]="{'is-invalid': submitted && f['kewarganegaraan'].errors}"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      (change)="onNationalityChange($event)"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option value="WNI">WNI</option>
                      <option value="WNA">WNA</option>
                    </select>
                  </div>
                </div>

                <div *ngIf="f['kewarganegaraan'].value == 'WNA'" class="d-flex flex-column gap-3 mt-3 px-3">
                  <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                    <div style="white-space: nowrap; flex: 1;">Negara</div>
                    <div class="position-relative" style="flex: 1.5;">
                      <select
                        formControlName="negara"
                        [ngClass]="{'is-invalid': submitted && f['negara'].errors}"
                        class="form-select rounded-3 py-2"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      >
                        <option value="" selected disabled>Pilih</option>
                        <option [value]="country.name" *ngFor="let country of listsCountry">
                          {{ country.name }}
                        </option>
                      </select>
                      <div *ngIf="submitted && f['negara'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                        Negara is required
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="f['kewarganegaraan'].value == 'WNI'" class="d-flex flex-column gap-3 mt-3 px-3">
                  <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                    <div style="white-space: nowrap; flex: 1;">Alamat <span class="text-danger">*</span></div>
                    <div class="position-relative" style="flex: 1.5;">
                      <input
                        class="form-control rounded-3 py-2"
                        formControlName="alamat"
                        [ngClass]="{'is-invalid': submitted && f['alamat'].errors}"
                        placeholder=""
                        style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                      />
                      <div *ngIf="submitted && f['alamat'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                        Alamat is required
                      </div>
                    </div>
                  </div>

                  <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                    <div style="white-space: nowrap; flex: 1">
                      RT <span class="text-danger">*</span>
                    </div>
                    <div class="position-relative" style="flex: 1.5">
                      <input
                        class="form-control rounded-3 py-2"
                        formControlName="rt"
                        [ngClass]="{ 'is-invalid': submitted && f['rt'].errors }"
                        placeholder=""
                        style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                      />
                      <div *ngIf="submitted && f['rt'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                        RT is required
                      </div>
                    </div>
                  </div>

                  <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                    <div style="white-space: nowrap; flex: 1">
                      RW <span class="text-danger">*</span>
                    </div>
                    <div class="position-relative" style="flex: 1.5">
                      <input
                        class="form-control rounded-3 py-2"
                        formControlName="rw"
                        [ngClass]="{ 'is-invalid': submitted && f['rw'].errors }"
                        placeholder=""
                        style="border: 1px solid rgb(170, 170, 170); width: 100%; text-indent: 10px;"
                      />
                      <div *ngIf="submitted && f['rw'].errors" class="mt-2" style="color: rgb(255, 126, 126)">
                        RW is required
                      </div>
                    </div>
                  </div>

                  <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                    <div style="white-space: nowrap; flex: 1">
                      Provinsi <span class="text-danger">*</span>
                    </div>
                    <div class="position-relative" style="flex: 1.5">
                      <select
                        formControlName="provinsi"
                        [ngClass]="{'is-invalid': submitted && f['provinsi'].errors}"
                        class="form-select rounded-3 py-2"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                        (change)="changeKabupaten($event)"
                      >
                        <option value="" selected disabled>Pilih</option>
                        <option [value]="data.provinsi" *ngFor="let data of listsProvinsi">{{ data.provinsi }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                    <div style="white-space: nowrap; flex: 1">
                      Kota/Kabupaten <span class="text-danger">*</span>
                    </div>
                    <div class="position-relative" style="flex: 1.5">
                      <select
                        formControlName="kota_kab"
                        [ngClass]="{'is-invalid': submitted && f['kota_kab'].errors}"
                        class="form-select rounded-3 py-2"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                        (change)="changeKecamatan($event)"
                      >
                        <option value="" selected disabled>Pilih</option>
                        <option [value]="data.kabupaten" *ngFor="let data of listsKabupaten">
                          {{ data.kabupaten }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                    <div style="white-space: nowrap; flex: 1">
                      Kecamatan <span class="text-danger">*</span>
                    </div>
                    <div class="position-relative" style="flex: 1.5">
                      <select
                        formControlName="kecamatan"
                        [ngClass]="{'is-invalid': submitted && f['kecamatan'].errors}"
                        class="form-select rounded-3 py-2"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                        (change)="changeKelurahan($event)"
                      >
                        <option value="" selected disabled>Pilih</option>
                        <option [value]="data.kecamatan" *ngFor="let data of listsKecamatan">
                          {{ data.kecamatan }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                    <div style="white-space: nowrap; flex: 1">
                      Desa/Kelurahan <span class="text-danger">*</span>
                    </div>
                    <div class="position-relative" style="flex: 1.5">
                      <select
                        formControlName="desa_kelurahan"
                        [ngClass]="{'is-invalid': submitted && f['desa_kelurahan'].errors}"
                        class="form-select rounded-3 py-2"
                        style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                      >
                        <option value="" selected disabled>Pilih</option>
                        <option [value]="data.kelurahan" *ngFor="let data of listsKelurahan">
                          {{ data.kelurahan }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <hr />
              </div>

              <div class="d-flex flex-column gap-3 mt-3 px-3">
                <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-3 justify-content-between">
                  <div style="white-space: nowrap; flex: 1">Lokasi Ujian</div>
                  <div class="position-relative" style="flex: 1.5">
                    <select
                      formControlName="lokasi_ujian"
                      [ngClass]="{'is-invalid': submitted && f['lokasi_ujian'].errors}"
                      class="form-select rounded-3 py-2"
                      style="border: 1px solid rgb(170, 170, 170); outline: none; width: 100%; text-indent: 10px;"
                    >
                      <option value="" selected disabled>Pilih</option>
                      <option [value]="dataLokasi.nama_lokasi" *ngFor="let dataLokasi of listLokasiUjian">
                        {{ dataLokasi.nama_lokasi }}
                      </option>
                      
                    </select>
                  </div>
                </div>

                <div>
                  <button
                    class="text-white rounded-3 px-5 py-2"
                    type="submit"
                    style=" width: fit-content; float: right; background: rgb(6, 149, 80); border: none;"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- ENOF ISI DATA SANTRI-->

        <div *ngIf="showStep4" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: 1px solid black">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="fw-semibold">4. Validasi Dokumen</div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="currentStep === 'DOCUMENT_VALIDATION'" class="w-100 p-5 d-flex flex-column align-items-center" style="background: rgb(240, 240, 240)">
          <!-- <div class="d-flex flex-column gap-3 mt-3">
            <div class="text-justify">
              <h5>
                SURAT PERNYATAAN (<a target="_blank" href="{{ suratPernyataanUrl }}">WAJIB DI PRINT</a>)
              </h5>
            </div>
            <div>&nbsp;</div>
          </div> -->

          <div class="d-flex flex-column gap-3 mt-3">
            <div class="text-justify" [innerHtml]="contentRegis | safeHtml"></div>
          </div>
          <div class="d-flex flex-column custom-width gap-3 mt-3">
            <div style="text-align: center">
              <br />
              <hr />
              Upload Kelengkapan Berkas / Dokumen
              <hr />
            </div>
            <div *ngFor="let berkas of listSiswaDokumen; let i = index" class="d-flex w-100 flex-column gap-2">
              <div class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-5 justify-content-between">
                <div style="white-space: nowrap">
                  <img *ngIf="berkas.dokumen != null" src="../../assets/images/check.png"/>
                  <img *ngIf="berkas.dokumen == null" src="../../assets/images/no-check.png"/>
                  {{ berkas.nama_dokumen }}
                </div>
                <div class="responsive-input position-relative p-2 responsive-input-upload" style="border: 1px solid rgb(170, 170, 170)">
                  <label *ngIf="berkas.dokumen == null" class="text-muted" for="file{{ i }}" style="cursor: pointer">Upload File</label>
                  <label *ngIf="berkas.dokumen != null" class="text-muted" for="file{{ i }}" style="cursor: pointer">Re-Upload File</label>
                  <input
                    id="file{{ i }}"
                    type="file"
                    (change)="selectFile($event, berkas)"
                    class="ms-2 custom-file-input"
                  />
                  <label for="file{{ i }}" style="cursor: pointer">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIwSURBVHgB5VVLbuJAEG0bIyHBgiOYG+QGMTeYbPgusE8wkxMETpDJCexZ8V2QE8ANwpxgPDdAQogFv3nPU3YcBzsikaIoKcnq7urq+rz6WKnPQlrWpeu65VKp9P14PNo4msJe4PP2+/19s9n01VuMjEajCywzfOUUEX+73V612+2FeoH0U8x+v2+GBjRNm8Praq1W0/hxTx7uzHw+PxPZTDJOMg3DBUTlw+Fw12g0fsTvANEcy3w8HnuQ6VAW56o6xwhhwmMLW3+z2XQz3hKmDmXx5oj9Utf1KSDsJXP1DC54b8l27jjOMs0ClN8mWIzczuVyfxDlTaoRVhO8uRQlf1UGITcOlF5jrUiuKmDfydvuYDCIYI6qiwmEF0y2KawpHl+pM4nK4SijXK7X6wrRiCKJGQhL8pt6BaFQfkr1lQuFAtvgP1yIwhIDPqxXCQW/pAJizSQnMU8S4PodKNd1i6sRPwDje0m2F3/EXBWLxVvp/ADzyWRirlar66ziCMmQR0uEmCoEAw/qMVdKHLLBt7CtJOWh6xI6WRwB9LoYWUhEHXqdYstXEiHkf8n5GQFOzjrmwm+1WtPAaHgJnGfShAs0lHNqJg2Hwy68vIFcr16vd+N3rE50Pw0Epcucoik97qOO3+12jlTYBWbSAzwKrxyUsqdSCHI2FlciVLL2QgOkqIQ5Cjj8klCAp7Iods+xwl9ANRnlk9klM8dWZ5B47GXJ6Ood6OMZQW/48fXr0T/YMDh0nF/2tAAAAABJRU5ErkJggg=="
                      class="position-absolute responsive-image"
                      style=" color: rgb(6, 149, 80); right: 5%; top: 50%; transform: translate(5%, -50%);"
                    />
                  </label>
                </div>
              </div>
              <div *ngIf=" currentFile && currentBerkas && currentBerkas.id === berkas.id " class="d-flex w-100 flex-column gap-2 flex-lg-row gap-lg-5 justify-content-between">
                <div style="white-space: nowrap"></div>
                <div class="responsive-input position-relative p-2 responsive-input-upload" style="border: 1px solid rgb(170, 170, 170)">
                  <div class="progress my-1">
                    <div
                      class="progress-bar progress-bar-info"
                      role="progressbar"
                      attr.aria-valuenow="{{ progress }}"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      [ngStyle]="{ width: progress + '%' }"
                    >
                      {{ progress }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
          </div>
          <div class="d-flex w-100 justify-content-center">
            <button
              (click)="onSubmitDocument()"
              class="py-2 rounded-3 mt-3 px-5 text-white fw-bold"
              style="background: rgb(6, 149, 80); border: none"
            >
              Konfirmasi
            </button>
          </div>
        </div>

        <div *ngIf="showStep5" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: 1px solid black">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="fw-semibold">5. Ujian Masuk</div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="currentStep === 'ENTRANCE_EXAMINATION'" class="w-100 p-5 d-flex flex-column align-items-center" style="background: rgb(240, 240, 240)">
          <div class="d-flex flex-column gap-3 mt-3">
            <!-- <div class="text-justify" [innerHtml]="contentRegis | safeHtml"></div> -->
          </div>

          <div class="row">
            <div class="col-sm-5">
              <div class="row" #examCard id="toPDF">
                <div class="kartu-ujian">
                  <img src="./assets/images/Logo-DN.png" class="img-responsive" style="width: 45px"/>
                  <h5 style="margin-top: 1px; font-size: small">
                    <b>
                      KARTU SELEKSI<br />
                      SISWA BARU 2023-2024<br />
                      PONDOK PESANTREN DARUNNAJAH 1 JAKARTA
                    </b>
                  </h5>

                  <div class="pas-foto">
                    <img [src]="imgSrc || './assets/images/empty-image.png'" class="img-thumbnail" style="height: 100%; width: 100%"/>
                  </div>

                  <div style="height: 0.2cm"></div>
                  <div class="div-tbl-ujian">
                    <table border="0" style="width: 100%" class="tbl-ujian">
                      <tbody>
                        <tr>
                          <td width="40%">Nama</td>
                          <td class="ng-binding">: {{ calonSiswa.nama_lengkap }}</td>
                        </tr>

                        <tr>
                          <td>No. Registrasi</td>
                          <td class="ng-binding">: {{ calonSiswa.regnumber }}</td>
                        </tr>

                        <tr>
                          <td>Jenjang Pendidikan</td>
                          <td class="ng-binding">: {{ currentLembaga }} / {{ currentTingkat }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <p class="text-center">
                      <i class="fa fa-globe"></i> www.darunnajah.com<br />
                      <i class="fa fa-instagram"></i> Darunnajah
                      <i class="fa fa-facebook-square"></i> Darunnajah
                    </p>
                  </div>
                  <br />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <button class="btn btn-success btn-block" (click)="print()">
                    <i class="ti-printer"></i> PRINT
                  </button>
                </div>
                <div class="col-sm-4">
                  <input type='file' #photoUpload (click)="photoUpload.value=''" style="display: none;" (change)="uploadPhoto($event);" />
                  <button class="btn btn-warning btn-block" (click)="photoUpload.click()">
                    <i class="fa fa-upload"></i> Photo
                  </button>
                </div>
              </div>
            </div>

            <div class="col-sm-7">
              <div class="text-justify" [innerHtml]="contentRegis | safeHtml"></div>
            </div>
          </div>
        </div>

        <div *ngIf="showStep6" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: 1px solid black">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="fw-semibold">
            6. Surat Keputusan Dan Biaya Santri Baru 2023/2024
          </div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="currentStep === 'ENTRY_TUITION_FEE'" class="w-100 p-5 d-flex flex-column align-items-center" style="background: rgb(240, 240, 240)">
          <div class="row">
            <div>
              <div class="panel panel-warning ng-scope">
                <div class="panel-heading">
                  <strong>SURAT KEPUTUSAN</strong>
                </div>
                <div class="panel-body">
                  <div class="main">
                    <p class="ng-binding"></p>
                  </div>

                  <div class="row">
                    <div [innerHtml]="contentRegis | safeHtml" class="col-sm-12"></div>
                  </div>
                </div>
                <div class="panel-footer">
                  <!--button class="btn btn-primary pull-right" ng-click="fnPangkalInfo()">Selanjutnya</button-->
                  <br />
                  <br />
                </div>
              </div>

              <div class="panel panel-warning ng-scope">
                <div class="panel-heading">
                  <strong>LAMPIRAN SURAT KEPUTUSAN</strong>
                </div>
                <div class="panel-body">
                  <div class="main">
                    <p class="ng-binding">
                      Hasil Tes Seleksi Masuk Pondok Pesantren Darunnajah 1
                      Jakarta Tahun Pelajaran 2023-2024 <br />memutuskan:
                    </p>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>Nama</td>
                            <td>:</td>
                            <td class="ng-binding">{{ calonSiswa.nama_lengkap }}</td>
                          </tr>
                          <tr>
                            <td>No. Registrasi</td>
                            <td>:</td>
                            <td class="ng-binding">{{ calonSiswa.regnumber }}</td>
                          </tr>
                          <tr>
                            <td>Kelulusan</td>
                            <td>:</td>
                            <td class="ng-binding"></td>
                          </tr>
                          <tr>
                            <td>Tanggal</td>
                            <td>:</td>
                            <td class="ng-binding"></td>
                          </tr>
                          <tr>
                            <td>Tingkatan</td>
                            <td>:</td>
                            <td class="ng-binding">{{ currentLembaga }} / {{ currentTingkat }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="panel-footer">
                  <!--button class="btn btn-primary pull-right" ng-click="fnPangkalInfo()">Selanjutnya</button-->
                  <br />
                  <br />
                </div>
              </div>

              <!-- ngIf: ket_lulus.status_kelulusan!='tidak_lulus' && !tanpaPG -->
              <div class="panel panel-warning">
                <div class="panel-heading">
                  <strong>INFORMASI TAGIHAN</strong>
                </div>
                <div class="panel-body">
                  <div class="row">
                    <div class="col-sm-12">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>Kode Bank</td>
                            <td>:</td>
                            <td class="ng-binding">451</td>
                          </tr>
                          <tr>
                            <td>Nama Bank</td>
                            <td>:</td>
                            <td class="ng-binding">Bank Syariah Indonesia</td>
                          </tr>
                          <!-- ngIf: siswaData.uid_sekolah != 26 -->
                          <tr>
                            <td>No. Virtual Account / Rekening</td>
                            <td>:</td>
                            <td class="ng-binding"></td>
                          </tr>
                          <tr>
                            <td>Total Tagihan</td>
                            <td>:</td>
                            <td class="ng-binding">Rp. 0</td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>Keterangan</th>
                            <th class="text-right">Jumlah</th>
                          </tr>
                          <!-- ngRepeat: tag in listDetail -->
                          <tr>
                            <th>Total</th>
                            <th class="text-right ng-binding">0</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr width="100%" />
                  <!-- ngIf: siswaData.uid_sekolah==2 -->
                </div>
              </div>
              <!-- end ngIf: ket_lulus.status_kelulusan!='tidak_lulus' && !tanpaPG -->
            </div>
          </div>
        </div>

        <div *ngIf="showStep7" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: 1px solid black">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="fw-semibold">7. Informasi Santri Baru 2023/2024</div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="currentStep === 'INFORMATION'" class="w-100 p-5 d-flex flex-column align-items-center"style="background: rgb(240, 240, 240)">
          <div class="row">
            <div class="col-sm-12">
              <div [innerHtml]="contentRegis | safeHtml"></div>
              <!-- <ng-container *compile="contentRegis; context: this"></ng-container> -->
            </div>
          </div>
        </div>

        <div *ngIf="!showDoneStep2 && !showStep2" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: none; background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="fw-semibold" style="color: rgb(170, 170, 170)">
            2. Bayar Biaya Pendaftaran
          </div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="!showDoneStep3 && !showStep3" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: none; background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="fw-semibold" style="color: rgb(170, 170, 170)">
            3. Isi Data Pendaftaran
          </div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="!showDoneStep4 && !showStep4" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: none; background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="fw-semibold" style="color: rgb(170, 170, 170)">
            4. Validasi Dokumen
          </div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="!showDoneStep5 && !showStep5" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: none; background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div class="fw-semibold" style="color: rgb(170, 170, 170)">
            5. Ujian Masuk
          </div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="!showDoneStep6 && !showStep6" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: none; background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div
            id="bayarUlang"
            class="fw-semibold"
            style="color: rgb(170, 170, 170)"
          >
            6. Surat Keputusan Dan Biaya Santri Baru 2023/2024
          </div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="!showDoneStep7 && !showStep7" class="rounded-3 position-relative d-flex align-items-center gap-4 py-3 px-4 w-100" style="border: none; background: rgb(242, 242, 242)">
          <img
            class="responsive-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAChSURBVHgB7ZZhDUIxDITbBgE4ACnLjAAKAAUPFMBz8JQskwIOpmDjLkCCgPKvlzRrtuS+Zn+uKlDOeRpjnNCuxUdNVZdSyllhfqM5asHDU3y0BWCHuq5gvKd5rfUgjkop8TiavL/Fa/JfPeht8mcFIAABCEAAAvAFNDPbiLM+no2ZPKOZEP7Se3eJTpoz6xH6s/ICAX3nFiCOawsHxyJxeQGUazgRQODbdAAAAABJRU5ErkJggg=="
            alt=""
          />
          <div id="bayarUlang" class="fw-semibold" style="color: rgb(170, 170, 170)">
            7. Informasi Santri Baru 2023/2024
          </div>
          <img
            class="responsive-image position-absolute"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgBjZLBEYIwFET9oQGPHCMVUALePFqClmAF4s2jJdCB2gDk6JEOSAdiBbjf+TBMDJCdYQLJ7vsLA62gDMKicT2MMe0qQIissRwkY4k3iOgt53XXddslGDIamUqGt8hslONJ2SDTJpvAcxcI6+dVPB3EYwhMINwk7fc4y4yIH6y1tdb6A9NOzmOlVNw0zTMAUvB91G8C9gIMXsr6ZkmSaIYtQVjkqZ8jdB4MRAVCqQM5AXIb5yIXhGbGbcavOgJfqqq6urk/0ARsgJRlmfsyXpAPNgcJEr7ZXv78WX0B3n1xxHjMqcoAAAAASUVORK5CYII="
            alt=""
            style="right: 2%; top: 50%; transform: translate(2%, -50%)"
          />
        </div>

        <div *ngIf="showInfo" class="d-flex w-100 justify-content-end">
          <button (click)="goToPayRegistrationPage()" class="py-2 rounded-3 mt-3 px-5 text-white fw-bold" style="background: rgb(6, 149, 80); border: none">
            Lanjut
          </button>
        </div>
      </div>
    </div>
    <footer class="w-100 d-flex justify-content-center py-3 text-white" style="background: rgb(6, 149, 80)">
      @Copyright 2022 - Darunnajah System
    </footer>
  </div>
</div>
