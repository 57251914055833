import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'phone-icon',
  templateUrl: './PhoneIcon.component.html',
})

export class PhoneIconComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
