<div class="d-flex gap-5 align-items-center justify-content-center" style="height: 100vh">
  <div class="p-3 px-5 shadow d-flex flex-column justify-content-center align-items-center">
    <img src="./assets/images/logo-DN-large.png" alt="" style="max-width: 50%"/>
    <div class="fw-bold fs-4 mt-3">DARUNNAJAH SYSTEM</div>
    <div class="text-center mt-4 fs-6">
      Selamat Datang di Sistem Pendaftaran Online<br />Pondok Pesantren
      Darunnajah
    </div>
    <div (click)="gotoRegistrationPage()" class="fw-bolder btn-warning-o rounded-3 px-5 py-2 mt-5 gap-3 w-100 d-flex align-items-center py-3 justify-content-center" style="cursor: pointer; color: rgb(6, 149, 80); font-size: 1.1rem" >
      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEKSURBVHgBnZLNTcNAEIXfbuwFKReX4HTgDjAVsFRAcg1C4AqgA0Ao4hh3wNIBHRA6oIP4Gudn82wlUmKvldifZHk1+96MZ8YCDvyJfhTAE48hn5kF3pf3Jq3qRDWgJvqZr5eaUCBZjM1bs/lVB0phDjdZnmOAxGT7gDy89TxEaCbwVdkGnGYhkaEFtZ45rF8GI4fyPx+bQWPlgp7EbSGsGqXAdT2fg8tPHa43iHl5ZS3++n2k2ci0aukkR5W9Dx2LHm4YDPljBBXtzK7xvXowP8dm7tdTmHIAGidg0nSZIyn2XQ7Mv8DXOcZdtWGhL89czZCBKVrCLxhJprpDF+iTwiJGB+iLJLoTbAFPXj+MqyC1aQAAAABJRU5ErkJggg=="
        alt="user"
      />
      <div>Daftar Santri Baru</div>
    </div>
    <div (click)="gotoLoginPage()" class="rounded-3 px-5 py-2 mt-3 gap-3 w-100 d-flex text-white align-items-center py-3 justify-content-center" style="cursor: pointer; background: rgb(6, 149, 80); font-size: 1.1rem">
      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACESURBVHgB1ZNbCoAgEEWnNqpLaAfVSm0HtYPJCxkSjHdAfzogfihnXiriQFWT2uAseDxMBM5JOoEF+yyDmBuRIpb0grqf+ldyT0t5rYyUyajIK3tFjtHWREs0bGpNcqBQZbMZd2izqcQrSkziFWFiixCK6Ed/7RM1Ge+q9OYYkdGV134D9z47JdetmhIAAAAASUVORK5CYII="
        alt="user"
      />
      <div class="fw-semibold">Login</div>
    </div>
    <div class="d-flex flex-column my-3 text-center">
      <a href="/" style="color: rgb(38, 82, 152)">Video Panduan Regristrasi Online</a>
      <a href="/" style="color: rgb(38, 82, 152)">Panduan Registrasi</a>
      <a href="/" style="color: rgb(38, 82, 152)">Brosur Pendaftaran Siswa Baru</a>
    </div>
  </div>
</div>
